<template>
    <div>
      <b-button  v-if="interfaceChosen=='Listing' && (can('Make complaint') || isPrincipal())" v-b-toggle.sidebar-reclamation v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="md" variant="primary" @click="$emit('is-update-function', false)">
        <feather-icon icon="PlusIcon" />
        <span class="align-middle">Enregistrer une réclamation</span>
      </b-button>
      <b-sidebar id="sidebar-reclamation" ref="sideBarReclamation" backdrop bg-variant="white" no-header right shadow sidebar-class="sidebar-lg" @hidden="clearDataSideBar">
        <template>
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">
              <span v-if="isUpdate"> Modifier la réclamation {{ reclamation.num_reclamation }} </span> <span v-else>Enregistrer une réclamation</span>
            </h5>

            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideSideBare" />
          </div>
          <validation-observer ref="reclamationComposeRules">
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <h4>INFORMATIONS</h4>
            <b-form-group label="N° de la réclamation*" label-for="num_reclamation">
              <validation-provider #default="{ errors }" name="n° de la réclamation" rules="required">
                <b-form-input id="num_reclamation" v-model="reclamation.num_reclamation" :state="errors.length > 0 ? false : null" placeholder="N° de la réclamation" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date de réclamation*" label-for="date-reclamation">
              <validation-provider #default="{ errors }" name="date de réclamation" rules="required">
                <flat-pickr id="date-reclamation" v-model="reclamation.date_reclamation" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date de réclamation" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Canal de réclamation*" label-for="canal-reclamation">
              <validation-provider #default="{ errors }" name="canal de réclamation" rules="required">
                <b-form-select v-model="reclamation.canal_reclamtion"  :state="errors.length > 0 ? false : null" :options="canalOptions" id="canal-reclamation" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date d'accusé de réception*" label-for="date-réception">
              <validation-provider #default="{ errors }" name="date d'accusé de réception" rules="required">
                <flat-pickr id="date-réception" v-model="reclamation.date_acc_recep" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date d'accusé de réception" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Déclarant*" label-for="declarant">
              <validation-provider #default="{ errors }" name="déclarant" rules="required">
                <b-form-select v-model="reclamation.declarant" id="declarant" :state="errors.length > 0 ? false : null" :options="declarantOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <h4>LE RECLAMANT</h4>

            <b-form-group label="Statut du reclamant*" label-for="statut">
              <validation-provider #default="{ errors }" name="statut du réclamant" rules="required">
                <b-form-select id="statut" v-model="reclamation.statut" :state="errors.length > 0 ? false : null" :options="statusOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Existe-t'il dans votre CRM ?" class="inline-legend mt-2">
              <b-form-radio-group v-model="reclamation.is_client" :options="prospectBoolean" class="demo-inline-spacing" name="radio-inline-pm" />
            </b-form-group>

            <div v-if="!reclamation.is_client">
              <b-form-group label="Nom de réclamant*" label-for="nom">
                <validation-provider #default="{ errors }" name="nom de réclamant" rules="required">
                  <b-form-input id="nom" v-model="reclamation.reclamant.nom" :state="errors.length > 0 ? false : null" placeholder="Nom de réclamant" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Prenom de réclamant*" label-for="prenom">
                <validation-provider #default="{ errors }" name="prenom de réclamant" rules="required">
                  <b-form-input id="prenom" v-model="reclamation.reclamant.prenom" :state="errors.length > 0 ? false : null" placeholder="Prenom de réclamant" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div>
            <div v-else class="mb-2">
              <validation-provider #default="{ errors }" name="réclamant" :rules="`requiredReclamant:${JSON.stringify(initialState().reclamation.reclamant)}`">
                <b-form-group :state="errors.length > 0 ? false : null" label="Réclamant*" label-for="prospect">
                  
                  <v-select  v-model="reclamation.reclamant" 
                  :close-on-select="true" 
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
                  :options="items" 
                  class="flex-grow-1" 
                  input-id="client" 
                  label="client" 
                  placeholder="Réclamant" 
                  @input="resetReference()"
                  :filterable="false"
                  @open="onOpen"
                  @close="onClose"
                  autocomplete
                  @search="autocompleteModeles"
                  @scroll.native="onScroll" 
                  ref="vSelect">
                    <template #option="{ avatar, client, categorie,nameClient }">
                      <b-avatar :src="avatar" size="sm" />
                      <span class="ml-50"> {{ nameClient}} </span>
                      <b-badge :variant="categorieVariant(categorie)" class="mr-2 float-right">
                        {{ capitalizeFirstLetter(categorie) }}
                      </b-badge>
                    </template>

                    <template #selected-option="{ avatar, client,nameClient }">
                      <b-avatar :src="avatar" class="border border-white" size="sm" />
                      <span class="ml-50"> {{ nameClient}}</span>
                    </template>

                    <template #list-footer>
                      <li v-if="loading" class="loader">
                        <span>Chargement des clients...</span>
                      </li>
                      <li v-if="!loading && !hasMore">
                        <span>Toutes les clients sont chargées</span>
                      </li>
                    </template>

                    <div slot="no-options">Aucune prospect disponible.</div>
                  </v-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <h4>DETAIL DE LA RECLAMATION</h4>

            <b-form-group label="Assureur" label-for="assureur">
                <v-select v-model="reclamation.assureur_id" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :getOptionKey="assureur => assureur.value + assureur.text" :options="assureursOptions" :reduce="assureur => assureur.value" label="text" placeholder="assureur">
                  <template #option="{ text }">
                    <span class="ml-50"> {{ text }} </span>
                  </template>
                  <div slot="no-options">Aucun assureur disponible.</div>
                </v-select>
            </b-form-group>

            <b-form-group label="Motif de la réclamation*" label-for="motif">
              <validation-provider #default="{ errors }" name="motif de la réclamation" rules="required">
                <b-form-select v-model="reclamation.motif" :state="errors.length > 0 ? false : null" :options="motifOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Service concerné*" label-for="Service">
              <validation-provider #default="{ errors }" name="service concerné" rules="required">
                <b-form-select v-model="reclamation.service" :state="errors.length > 0 ? false : null" :options="serviceOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Précisez*" label-for="autre-service" v-if="reclamation.service == 'AUTRE'">
              <validation-provider #default="{ errors }" name="autre service" rules="required">
                <b-form-input id="autre-service" v-model="reclamation.autre_service" :state="errors.length > 0 ? false : null" placeholder="Précisez" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Pris en charge par*" label-for="charge">
              <validation-provider #default="{ errors }" name="pris en charge par" rules="required">
                  <v-select v-model="reclamation.user" :close-on-select="true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="commercialOptions"
                    class="flex-grow-1"
                    input-id="commercial" label="nom" placeholder="Commercial"
                    :state="errors.length > 0 ? false : null">
                    <template #option="{ avatar, nom }">
                      <b-avatar :src="avatar" size="sm"/>
                      <span class="ml-50"> {{ nom }}</span>
                    </template>

                    <template #selected-option="{ avatar, nom }">
                      <b-avatar :src="avatar" class="border border-white" size="sm"/>
                      <span class="ml-50"> {{ nom }}</span>
                    </template>
                    <div slot="no-options">Aucune commercial disponible.</div>
                  </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Niveau de traitement*" label-for="traitement">
              <template v-slot:label>
                Niveau de traitement*
                <span
                  v-b-popover.hover.right.html="`
                       Pour plus d’information, rendez-vous sur la FAQ
                      <ul>
                        <li>Niveau 1 : gestion d’une réclamation par la relation client</li>
                        <li>Niveau 2 : gestion d’une réclamation par le service réclamation</li>
                        <li>Niveau 2S : traitement sensible</li>
                        <li>Niveau 3 : gestion d’une réclamation par le médiateur</li>
                      </ul>
                    `"
                  title="Niveau de traitement"
                  variant="outline-primary"
                >
                  <feather-icon icon="InfoIcon" class="cursor-pointer" size="21" />
                </span>             
              </template>
              <validation-provider #default="{ errors }" name="niveau de traitement" rules="required">
                <b-form-select v-model="reclamation.niveau" :state="errors.length > 0 ? false : null" :options="niveauOptions" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Référence (devis, contrat, sinistre etc.)" label-for="reference">
              <v-select v-if="reclamation.is_client" v-model="reclamation.reference" :options="devisContratData" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="id" label="id" placeholder="Sélectionner un devis/contrat">
                <template #option="{ numero ,type }">
                  <span class="ml-50"> {{ numero }}</span>
                  <b-badge :variant="sourceVariant(type)" class="mr-2 float-right"> {{ type === 'PROJET' ? 'DEVIS' : capitalizeFirstLetter(type) }} </b-badge>
                </template>

                <template #selected-option="{ numero }">
                  <span class="ml-50"> {{ numero }}</span>
                </template>
                <div slot="no-options">Aucune devis/contrat disponible.</div>
              </v-select>
              <b-form-input v-else id="num_reclamation" v-model="reclamation.reference.ref_externe" placeholder="N° de la réclamation" />
            </b-form-group>
            
            <b-form-group label="Commentaire*" label-for="commentaire" >
              <div class="commentaire">
                <validation-provider #default="{ errors }" name="commentaire" rules="required">
                  <quill-editor v-model="reclamation.comment" id="commentaire" :options="editorOptions('commentaire')" :class="errors.length > 0 ? 'is-invalid' : ''" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-form-group>

            <b-form-group label="Issue" label-for="issue">
                <b-form-select id="issue" v-model="reclamation.issue" :options="issueOptions" />
            </b-form-group>

            <div class="block-issue" v-if="reclamation.issue">
              <b-form-group label="Date de clôture*" label-for="date-cloture">
                <validation-provider #default="{ errors }" name="date de clôture" rules="required">
                  <flat-pickr id="date-cloture" v-model="reclamation.date_cloture" :config="configDate" class="form-control" :class="errors.length > 0 ? 'is-invalid' : ''" placeholder="Date de réclamation" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Réponse apportée*" label-for="reponse-apporte" >
                <div class="reponse-apporte">
                  <validation-provider #default="{ errors }" name="réponse apportée" rules="required">
                    <quill-editor id="reponse-apporte" v-model="reclamation.reponse_app" :options="editorOptions('reponse')" :class="errors.length > 0 ? 'is-invalid' : ''" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </b-form-group>

              <b-form-group label="Mesures correctives*" label-for="mesures-correctives" >
                <div class="mesures-correctives">
                  <validation-provider #default="{ errors }" name="mesures correctives" rules="required">
                    <quill-editor id="mesures-correctives" v-model="reclamation.mesure_cor" :options="editorOptions('mesure')" :class="errors.length > 0 ? 'is-invalid' : ''" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </b-form-group>
            </div>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary" @click="recordReclamation"> Enregistrer </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
   
    </div>
  </template>
  
  <script>
  import debounce from 'lodash.debounce';
  import { BAvatar, BBadge, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea, BFormSelectOption, BInputGroup, BSidebar, VBToggle, BFormFile, VBTooltip, BFormRadioGroup, BInputGroupPrepend, BOverlay, BFormRadio,VBPopover} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import flatPickr from 'vue-flatpickr-component'
  import { required } from '@validations'
  import vSelect from 'vue-select'
  import Cleave from 'vue-cleave-component'
  import moment from 'moment'
  import { ref } from '@vue/composition-api'
  import documentService from '@/shared/services/documentService'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import { quillEditor } from 'vue-quill-editor'
  import ReclamationOptions from "@/shared/constants/reclamation"
  import { rules } from '@core/libs/validations'
  
  export default {
    components: {
      quillEditor,
      BButton,
      BSidebar,
      BFormGroup,
      BFormInput,
      BForm,
      BInputGroup,
      BFormRadioGroup,
      BFormInvalidFeedback,
      BBadge,
      Cleave,
      vSelect,
      BFormSelect,
      flatPickr,
      BAvatar,
      VBTooltip,
      BFormTextarea,
      BFormSelectOption,
      BFormFile,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BInputGroupPrepend,
      BOverlay,
      BFormRadio,
      AppCollapse,
      AppCollapseItem
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      'b-popover' : VBPopover,
      Ripple
    },
    props: {
      reclamationForEdit: {
        type: Object
      },
      interfaceChosen: {
        type: String,
        default: "Listing",
        required: false
      },
      isUpdate: {
        type: Boolean,
        default: true,
        required: true
      },
      prospectOptions: {
        type: Array,
        default: [],
        required: true,
      },
      assureursOptions: {
        type: Array,
        default: [],
        required: true
      },
      projetContratData: {
        type: Array,
        default: [],
        required: false
      },
      commercialOptions: {
        type: Array,
        default: [],
        required: true
      },
      isFicheClient: {
        type: Boolean,
        default: false,
        required: false
      },
      isFicheContrat: {
        type: Boolean,
        default: false,
        required: false
      },
      tiersData: {
        type: Object,
        default: null,
        required: false
      }
    },
    data() {
      return this.initialState()
    },
    watch: {
      'reclamation.is_client': {
        handler(val) {
          if(!this.isUpdate && !this.isFicheContrat) {
            this.reclamation.reclamant = this.initialState().reclamation.reclamant
            this.reclamation.reference = this.initialState().reclamation.reference
          }
          // if(val) {
          //   if(this.prospectOptions.length > 0 && !this.isFicheClient) {
          //     this.reclamation.reclamant = this.prospectOptions[0]
          //   }
          //   if(this.projetContratData.length > 0) {
          //     this.reclamation.reference = this.projetContratData[0]
          //   }
          // } else {
          //   this.reclamation.reclamant = this.initialState().reclamation.reclamant
          //   this.reclamation.reference = this.initialState().reclamation.reference
          // }
        }
      },
      'reclamation.service': {
        handler(val) {
          if(val != 'AUTRE') {
            this.reclamation.autre_service = null
          }
        }
      },
      'reclamation.reclamant': {
        handler(val, old) {
          if(val && val.id && val.type && val.id != old.id) {
            this.$emit('fetch-data-ref', this.reclamation.reclamant)
          }
        }
      },
      isUpdate: {
        immediate: true,
        handler(val) {
          if(!val) {
            this.initCurrentClient()
          }
          this.isUpdateAction = val
        }
    },
    isUpdateAction: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reclamation = this.reclamationForEdit
          this.devisContratData = this.projetContratData
        } else {
          this.clearDataSideBar()
        }
      }
    },
    reclamationForEdit: {
      deep: true,
      handler(val) {
        if (this.isUpdateAction) {
          this.reclamation = val
        }
      }
    },
    projetContratData: {
      deep: true,
      handler(val) {
        this.devisContratData = val
      }
    },
    tiersData: {
      handler(val) {
        this.initCurrentClient()
      }
    }
    },
    computed: {
      categorieVariant() {
        const categorieColor = {
          PARTICULIER: 'light-danger',
          PROFESSIONNEL: 'light-warning',
          ENTREPRISE: 'light-success'
        }
        return categories => categorieColor[categories]
      },
    },
    mounted() {
      if(!this.isUpdate)
        this.clearDataSideBar()
    },
    created(){
    this.debouncedSearch = debounce(this.getProspectByScrool, 700);
    },
    methods: {
      initialState() {
        return {
          devisContratData: [],
          isUpdateAction: false,
          showLoading: false,
          currentUser: JSON.parse(localStorage.getItem('userData')),
          reclamation: {
            id: null,
            is_client: false,
            reclamant: {
              id: null,
              idClient: null,
              type: null,
              categorie: null,
              nameClient: null,
              client: null,
              nom: null,
              prenom: null
            },
            num_reclamation: null,
            date_reclamation: null,
            canal_reclamtion: null,
            date_acc_recep: null,
            declarant: null,
            statut: null,
            assureur_id: null,
            motif: null,
            service: null,
            autre_service: null,
            user: null,
            courtier_id: null,
            niveau: null,
            reference: {
              id: null,
              numero: null,
              source: null,
              type: null,
              ref_externe: null
            },
            comment: null,
            issue: null,
            date_cloture: null,
            reponse_app: null,
            mesure_cor: null
          },
          configDate: {
          dateFormat: 'd/m/Y',
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          }
          },
          prospectBoolean: ReclamationOptions['PROSPECTBOOLEAN'],
          snowOption: {
            theme: 'snow',
            placeholder: {
              'commentaire' : 'Ecrivez votre commentaire…',
              'reponse'     : 'Ecrivez la réponse apportée…',
              'mesure'      : 'Ecrivez les mesures correctives…'
            },
            modules: {
              toolbar: ['bold', 'italic', 'underline', 'strike']
            }
          },
          canalOptions : ReclamationOptions['CANALS'],
          declarantOptions: ReclamationOptions['DECLARANTS'],
          statusOptions : ReclamationOptions['STATUS'],
          motifOptions : ReclamationOptions['MOTIFS'],
          serviceOptions : ReclamationOptions['SERVICES'],
          niveauOptions : ReclamationOptions['NIVEAUX'],
          issueOptions : ReclamationOptions['ISSUES'],

          page: 1,
            items: [],
            selectedItem: null,
            loading: false, // Indique si les données sont en train de charger
            hasMore: true,  // Indique s'il reste des données à charger
            search: '',

            categories: [
              {
                1: 'PARTICULIER',
                2: 'PROFESSIONNEL',
                3: 'ENTREPRISE'
              },
              {
                1: 'light-danger',
                2: 'light-warning',
                3: 'light-success'
              }
            ],
        }
      },

      autocompleteModeles(filtre, loading) {
        this.items = [];
        this.search = filtre
        this.page = 1;
        this.hasMore = true;
        this.debouncedSearch()
        
      },
      onOpen() {
        if (!this.items.length) {
          this.getProspectByScrool(); 
        }
        this.$nextTick(() => {
          const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
          if (dropdownMenu) {
            dropdownMenu.addEventListener('scroll', this.onScroll);
            console.log("Scroll event attached");
          }
        });
      },
      onClose() {
        const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.removeEventListener('scroll', this.onScroll);
        }
      },
      getProspectByScrool(page = 1, scrool = false ) {
        if (this.loading || !this.hasMore) return;

        const dropdownMenu = this.$refs.vSelect.$el.querySelector('.vs__dropdown-menu');
        const previousScrollHeight = dropdownMenu ? dropdownMenu.scrollHeight : 0;

        this.loading = true;
        this.$http
          .post(`/portefeuilles/getDataPagination?page=${page}`, {
            type: 'CLIENT_SELECT',
            type_list: 'CLIENT',
            per_page: 20,
            recherche: this.search || ''  
          })
          .then((r) => {
            const data = r.data.data.data;
            if (data.length) {
              this.items = [...this.items, ...data];
            }
            if (!r.data.data.next_page_url) {
              this.hasMore = false; // Désactiver le chargement si plus de pages
            }
            this.page++; // Incrémenter la page pour le prochain appel
          })
          .catch((err) => {
            console.error('Erreur lors du chargement des données:', err);
          })
          .finally(() => {
            // this.search = ''
            this.loading = false; // Arrêter le spinner de chargement
            // Restaurer la position de défilement
            if (scrool) {
              this.$nextTick(() => {
                if (dropdownMenu) {
                  // Calculer la nouvelle hauteur et ajuster la position de défilement
                  const newScrollHeight = dropdownMenu.scrollHeight;
                  dropdownMenu.scrollTop += newScrollHeight - previousScrollHeight;
                }
              });
            }
            
          });
      },
      onScroll(event) {
        const tolerance = 5; // Tolérance de 5 pixels pour détecter le bas
        const dropdown = event.target;
        const atBottom = Math.abs(dropdown.scrollHeight - dropdown.scrollTop - dropdown.clientHeight) <= tolerance;

        console.log("Scroll détecté - Bas atteint : ", atBottom);

        if (atBottom && this.hasMore && !this.loading) {
          this.getProspectByScrool(this.page); // Charger la page suivante
        }
      },   
      
      resetReference() {
        this.reclamation.reference = this.initialState().reclamation.reference
      },
      getAuthCourtier(){
        let commercialSelected = {
          id:  this.currentUser.personne_physique.user_id,
          nom: this.currentUser.personne_physique.nom + ' ' + this.currentUser.personne_physique.prenom,
          type: this.currentUser.personne_physique.type
        }
        this.reclamation.user = commercialSelected
      },
      hideSideBare() {
        this.$refs.sideBarReclamation.hide()
      },
      recordReclamation(){
        this.showLoading = true
        this.$refs.reclamationComposeRules.validate().then(success => {
            if (success) {
              this.$http
                .post('/reclamations/storeReclamation', {
                  reclamation: this.reclamation
                })
                .then(res => {
                  if (res.data.success) {
                    this.showLoading = false
                    if(this.interfaceChosen == 'FicheReclamation'){
                      this.$emit('refresh-reclamation');
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.$emit('refresh-list-reclamations');
                    }
                      else {
                      this.$emit('record-reclamation-returned', res.data.data, res.data.message)
                      this.clearDataSideBar()
                    }

                    this.hideSideBare() 
                  }
                  this.showLoading = false
                })
                .catch(err => {
                  this.showLoading = false
                  console.error(err)
                })
            } else {
              this.showLoading = false
            }
        })
      },
      clearDataSideBar() {
        this.reclamation = this.initialState().reclamation
        this.devisContratData = this.initialState().devisContratData
        this.initCurrentClient()
        this.reclamation.courtier_id = this.currentUser.courtier_user[0].courtier_id
        this.getAuthCourtier()
        this.$refs.reclamationComposeRules.reset()
      },
      hideSideBare() {
        this.$refs.sideBarReclamation.hide()
      },
      editorOptions(fieldName) {
        return {
          theme: this.snowOption.theme,
          placeholder: this.snowOption.placeholder[fieldName],
          modules: this.snowOption.modules
        }
      },
      initCurrentClient() {
        if(!this.isUpdate && this.isFicheClient && this.tiersData) {
          this.reclamation.is_client = true
          this.reclamation.reclamant  = {
            id: this.tiersData.id,
            idClient: this.tiersData.id,
            type: this.tiersData.type,
            categorie: this.tiersData.categorie,
            client: this.tiersData.type === 'PERSONNE_MORALE' ? this.tiersData.denominationCommercial : this.tiersData.nom + ' ' + this.tiersData.prenom,
            nom: null,
            prenom: null
          }
        }
        else if(!this.isUpdate && this.isFicheContrat && this.tiersData && this.tiersData.contrable){
          console.log(this.tiersData)
           this.reclamation.is_client = true;
           this.reclamation.reclamant  = {
            id: this.tiersData.contrable.id,
            idClient: this.tiersData.contrable.id,
            type: this.tiersData.contrable.type,
            categorie: this.tiersData.contrable.categorie,
            client: this.tiersData.contrable.type === 'PERSONNE_MORALE' ? this.tiersData.contrable.denominationCommercial : this.tiersData.contrable.nom + ' ' + this.tiersData.contrable.prenom,
            nom: null,
            prenom: null
          }
          // reference: {
          //     id: null,
          //     numero: null,
          //     source: null,
          //     type: null,
          //     ref_externe: null
          //   },
          this.reclamation.reference.id = this.tiersData.id;
          this.reclamation.reference.numero = this.tiersData.police_no;
          this.reclamation.reference.source = this.tiersData.source;
          this.reclamation.reference.type = 'CONTRAT'
          
        }
      }
    }
  }
  </script>
  <style lang="scss">
  
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';

  .inline-legend legend {
    float: left;
    width: auto;
  }
  .inline-legend div[role='radiogroup'] {
    justify-content: end;
  }
  .inline-legend div[role='radiogroup'] > .custom-control {
    margin-top: 0;
  }
  
  </style>
  