var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.interfaceChosen == 'Listing' && (_vm.can('Make claim') || _vm.isPrincipal()))?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-sinistre",modifiers:{"sidebar-sinistre":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","size":"md"},on:{"click":function($event){return _vm.$emit('is-update-function', false)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" Enregistrer un sinistre")])],1):_vm._e(),_c('b-sidebar',{ref:"sideBarSinistre",attrs:{"id":"sidebar-sinistre","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.clearDataSideBar},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.isUpdate)?_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v(" Modifier le sinistre "+_vm._s(_vm.sinistre.numero_sinistre))]):_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v("Enregistrer un sinistre")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.hideSideBare}})],1),_c('validation-observer',{ref:"sinistreComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('h4',[_vm._v("INFORMATIONS")]),_c('b-form-group',{attrs:{"label":"N° du sinistre*","label-for":"numero_sinistre"}},[_c('validation-provider',{attrs:{"name":"n° sinistre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"numero_sinistre","state":errors.length > 0 ? false : null,"placeholder":"N° du sinistre"},model:{value:(_vm.sinistre.numero_sinistre),callback:function ($$v) {_vm.$set(_vm.sinistre, "numero_sinistre", $$v)},expression:"sinistre.numero_sinistre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('h4',[_vm._v("LE SINISTRE")]),_c('div',{staticClass:"form-group-wrapper"},[_c('b-form-group',{staticClass:"inline-legend mt-2 display-flex flex-column",attrs:{"label":"Le sinistré existe-t-il dans votre CRM ?"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.crmExiste,"name":"radio-inline"},model:{value:(_vm.sinistre.is_client),callback:function ($$v) {_vm.$set(_vm.sinistre, "is_client", $$v)},expression:"sinistre.is_client"}})],1)],1),(!_vm.sinistre.is_client)?_c('div',[_c('b-form-group',{attrs:{"label":"Nom du sinistré*","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"nom du sinistré","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nom","state":errors.length > 0 ? false : null,"placeholder":"Nom de sinistré"},model:{value:(_vm.sinistre.sinistree.nom),callback:function ($$v) {_vm.$set(_vm.sinistre.sinistree, "nom", $$v)},expression:"sinistre.sinistree.nom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Prenom de sinistré*","label-for":"prenom"}},[_c('validation-provider',{attrs:{"name":"prenom du sinistré","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"prenom","state":errors.length > 0 ? false : null,"placeholder":"Prenom de sinistré"},model:{value:(_vm.sinistre.sinistree.prenom),callback:function ($$v) {_vm.$set(_vm.sinistre.sinistree, "prenom", $$v)},expression:"sinistre.sinistree.prenom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1):_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Sinistré*","label-for":"prospect"}},[_c('v-select',{ref:"vSelect",staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.items,"input-id":"client","label":"client","placeholder":"Sinistré","filterable":false,"autocomplete":""},on:{"open":_vm.onOpen,"close":_vm.onClose,"search":_vm.autocompleteModeles},nativeOn:{"scroll":function($event){return _vm.onScroll.apply(null, arguments)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var categorie = ref.categorie;
var nameClient = ref.nameClient;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nameClient)+" ")]),_c('b-badge',{staticClass:"mr-2 float-right",attrs:{"variant":_vm.categorieVariant(categorie)}},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(categorie))+" ")])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var client = ref.client;
var nameClient = ref.nameClient;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nameClient))])]}},{key:"list-footer",fn:function(){return [(_vm.loading)?_c('li',{staticClass:"loader"},[_c('span',[_vm._v("Chargement des clients...")])]):_vm._e(),(!_vm.loading && !_vm.hasMore)?_c('li',[_c('span',[_vm._v("Toutes les clients sont chargées")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.sinistre.sinistree),callback:function ($$v) {_vm.$set(_vm.sinistre, "sinistree", $$v)},expression:"sinistre.sinistree"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune prospect disponible.")])])],1)],1),(_vm.sinistre.is_client)?_c('div',[_c('b-form-group',{staticClass:"inline-legend mt-2 display-flex flex-column",attrs:{"label":"Le contrat existe-t-il dans votre CRM ?"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.crmContratExiste,"name":"radio-inline-contrat"},model:{value:(_vm.sinistre.is_contrat),callback:function ($$v) {_vm.$set(_vm.sinistre, "is_contrat", $$v)},expression:"sinistre.is_contrat"}})],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Numéro de contrat*","label-for":"Numéro de contrat"}},[_c('validation-provider',{attrs:{"name":"numéro de contrat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.sinistre.is_client && _vm.sinistre.is_contrat)?_c('v-select',{attrs:{"options":_vm.projetContratData,"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"input-id":"id","label":"id","placeholder":"Sélectionner un contrat"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var numero = ref.numero;
var type = ref.type;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(numero))]),_c('b-badge',{staticClass:"mr-2 float-right",attrs:{"variant":_vm.sourceVariant(type)}},[_vm._v(" "+_vm._s(type === 'PROJET' ? 'DEVIS' : _vm.capitalizeFirstLetter(type))+" ")])]}},{key:"selected-option",fn:function(ref){
var numero = ref.numero;
return [_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(numero))])]}}],null,true),model:{value:(_vm.sinistre.reference),callback:function ($$v) {_vm.$set(_vm.sinistre, "reference", $$v)},expression:"sinistre.reference"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune contrat disponible.")])]):(!_vm.sinistre.is_client || !_vm.sinistre.is_contrat)?_c('b-form-input',{attrs:{"id":"contrat_no","placeholder":"Précisez Numéro de contrat*","state":errors.length > 0 ? false : null},model:{value:(_vm.sinistre.reference.ref_externe),callback:function ($$v) {_vm.$set(_vm.sinistre.reference, "ref_externe", $$v)},expression:"sinistre.reference.ref_externe"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.sinistre.is_client || !_vm.sinistre.is_contrat)?_c('div',[_c('b-form-group',{attrs:{"label":"Risque*","label-for":"risque"}},[_c('validation-provider',{attrs:{"name":"risque","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"risque","options":_vm.risqueOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.sinistre.risque_id),callback:function ($$v) {_vm.$set(_vm.sinistre, "risque_id", $$v)},expression:"sinistre.risque_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(!_vm.sinistre.is_client || !_vm.sinistre.is_contrat)?_c('div',[_c('b-form-group',{attrs:{"label":"Assureur","label-for":"assureur"}},[_c('b-form-select',{attrs:{"id":"assureur","options":_vm.assureurOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.sinistre.assureur_id),callback:function ($$v) {_vm.$set(_vm.sinistre, "assureur_id", $$v)},expression:"sinistre.assureur_id"}})],1)],1):_vm._e(),_c('h4',[_vm._v("DÉTAIL DU SINISTRE")]),_c('b-form-group',{attrs:{"label":"Date du sinistre*","label-for":"date-sinistre"}},[_c('validation-provider',{attrs:{"name":"date du sinistre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-sinistre","placeholder":"Date de sinistre","config":_vm.configDate},model:{value:(_vm.sinistre.date_sinistre),callback:function ($$v) {_vm.$set(_vm.sinistre, "date_sinistre", $$v)},expression:"sinistre.date_sinistre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Date de déclaration*","label-for":"date-declaration"}},[_c('validation-provider',{attrs:{"name":"date de déclaration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-declaration","placeholder":"Date de déclaration","config":_vm.configDate},model:{value:(_vm.sinistre.date_declaration),callback:function ($$v) {_vm.$set(_vm.sinistre, "date_declaration", $$v)},expression:"sinistre.date_declaration"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Statut du sinistre*","label-for":"statut"}},[_c('validation-provider',{attrs:{"name":"statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statutOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.sinistre.statut),callback:function ($$v) {_vm.$set(_vm.sinistre, "statut", $$v)},expression:"sinistre.statut"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type de sinistre*","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type de sinistre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.typeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.sinistre.type),callback:function ($$v) {_vm.$set(_vm.sinistre, "type", $$v)},expression:"sinistre.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Responsabilité*","label-for":"responsabilite"}},[_c('validation-provider',{attrs:{"name":"responsabilité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"responsabilite","placeholder":"Responsabilité","state":errors.length > 0 ? false : null},model:{value:(_vm.sinistre.responsabilite),callback:function ($$v) {_vm.$set(_vm.sinistre, "responsabilite", $$v)},expression:"sinistre.responsabilite"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Montant du sinistre","label-for":"montant-sinistre"}},[_c('validation-provider',{attrs:{"name":"montant-sinistre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"montant-sinistre","placeholder":"Montant du sinistre","state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.sinistre.montant),callback:function ($$v) {_vm.$set(_vm.sinistre, "montant", $$v)},expression:"sinistre.montant"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Sinistre en délégation*","label-for":"sinistre_en_delegation"}},[_c('validation-provider',{attrs:{"name":"sinistre en délégation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.prospectBoolean,"name":"radio-inline-pm"},model:{value:(_vm.sinistre.isDelegation),callback:function ($$v) {_vm.$set(_vm.sinistre, "isDelegation", $$v)},expression:"sinistre.isDelegation"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.sinistre.statut == 'CLOS')?_c('b-form-group',{attrs:{"label":"Date de clôture","label-for":"date-declaration"}},[_c('validation-provider',{attrs:{"name":"date de clôture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-declaration","placeholder":"Date de clôture","config":_vm.configDate},model:{value:(_vm.sinistre.date_cloture),callback:function ($$v) {_vm.$set(_vm.sinistre, "date_cloture", $$v)},expression:"sinistre.date_cloture"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Coordonnées de l’expert","label-for":"expert"}},[_c('validation-provider',{attrs:{"name":"coordonnées de l’expert"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"expert","placeholder":"Coordonnées de l’expert","state":errors.length > 0 ? false : null},model:{value:(_vm.sinistre.expert),callback:function ($$v) {_vm.$set(_vm.sinistre, "expert", $$v)},expression:"sinistre.expert"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Gestionnaire*","label-for":"gestionnaire"}},[_c('validation-provider',{attrs:{"name":"gestionnaire","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.gestionnaireOptions,"input-id":"gestionnaire","label":"nom","placeholder":"Gestionnaire","required":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.sinistre.gestionnaire),callback:function ($$v) {_vm.$set(_vm.sinistre, "gestionnaire", $$v)},expression:"sinistre.gestionnaire"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune gestionnaire disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordSinistre}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }