<template>
  <div>
    <b-overlay
        :show="showLoading"
        no-wrap
    />
    <div>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-1 mt-1">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1" v-if="interfaceChosen === 'Listing'">
            Devis ({{ allDataFprFetch.total ? allDataFprFetch.total : 0 }})
          </h1>
        </div>
        <div class="mr-1 mb-1" >
          <import-new-projet :assureurs-options="assureursOptions" :interface-chosen="interfaceChosen"
          :courtier-id="currentUser.courtier_user[0].courtier_id" :projet-for-update="projetForUpdate"
          :commercial-options="commercialOptions" :prospect-data="prospectData" :risques-options="risquesOptions"
          :user-id="currentUser.courtier_user[0].user_id"
          @record-projet-externe-returned="RecordProjetExterneReturned"
          @clear-data-projet-update="clearDataProjetUpdate" />
          <import-new-doc-projet :client-id="client_id_joindre" :client-type="client_type_joindre"
            :courtier-id="currentUser.courtier_user[0].courtier_id" :devis-id="devis_id_joindre"
            :etude-id="etude_id_joindre" :user-id="currentUser.courtier_user[0].user_id" @record-document-returned="recordDocumentReturned" />
        </div>
        
      </div>
      <!-- basic modal -->
      <b-modal id="modal_renewal" hide-footer centered :title="title_modal" @hidden="resetModal">
        <p class="mb-0">{{ msg_modal }}</p>
        <validation-observer ref="formRenewalRules">
          <b-form class="p-2" @submit.prevent="submitDuplicateProjet">
            <b-form-group label="Date d'effet*" label-for="date-effet">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                <flat-pickr id="date-effet" v-model="selected_date_effet" class="form-control"
                  placeholder="Date d'effet" :config="configDateEffet" autocomplete="off"
                  :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Confirmer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- basic modal -->
       <!-- basic modal -->
      <b-modal no-close-on-backdrop
      id="renvoyer_signature" ref="renvoyer_signature" hide-footer centered :title="getTitleRenvoyerSignatureModal"  @hidden="resetRenvoyerSignatureModal">
        <p class="mb-1">Merci de vérifier les données utiles à la signature ou les modifier si nécessaire</p>
        <validation-observer ref="formRenvoyerSignature">
          <b-form class="p-2" @submit.prevent="renvoyerSignature">

            <b-form-group  label-for="lien_signature">
              <b-form-radio-group
                id="lien_signature"
                v-model="renvoyerDataForm.lien_signature"
                :options="options_lien_signature"
              ></b-form-radio-group>                  
            </b-form-group>
        <p class="text-danger my-1" v-if="renvoyerDataForm.lien_signature=='resend'">(Pour modifier le numéro de téléphone, vous devez créer un nouveau lien)</p>

            <b-form-group label="Téléphone portable" label-for="telephone_client">
                      <div class="d-flex bd-highlight">
                        <div class="w-25 bd-highlight">
                              <v-select :disabled="renvoyerDataForm.lien_signature=='resend'"  v-model="renvoyerDataForm.indicatif" :close-on-select="true" :clearable="true"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="indicatif"  :reduce="indicatif =>indicatif.indicatif"
                                input-id="indicatif" label="indicatif" class="indicatif-chooser w-100  border rounded"
                            >
                                <template #option="{ indicatif,code }">
                                  <span> {{`${code.toUpperCase()} `+indicatif  }}</span>
                                </template>
                                <template #selected-option="{ indicatif }">
                                  <span> {{ indicatif }}</span>
                                </template>
                                <div slot="no-options">Aucune indicatif disponible.</div>
                            </v-select>
                        </div>
                      
                        <div class="w-75 bd-highlight">
                          <validation-provider #default="{ errors }" name="Téléphone portable" 
                            :rules="{ regex: /^(0|6|7)\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }">
                            <b-form-input :readonly="renvoyerDataForm.lien_signature=='resend'" id="telephone_client" style="border-radius: 0rem 0.357rem 0.357rem 0rem; border-left: none;" v-mask="'## ## ## ## ##'" v-model="renvoyerDataForm.telephone" placeholder="Téléphone portable" type="tel" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                          </validation-provider>
                          </div>
                      </div>
                    </b-form-group>
            <!--  -->
            <b-form-group label="Email" label-for="email">
                      <validation-provider #default="{ errors }" name="Email" rules="email">
                        <b-form-input id="email" v-model="renvoyerDataForm.email" placeholder="Email" :state="errors.length > 0 ? false : null" type="email" />
                        <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

            <div class="d-flex mt-2">
              <b-button :disabled="disableRenvoyer" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Valider
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="$refs['renvoyer_signature'].hide()">
                Annuler
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!--  -->

      <!-- basic modal -->
      <b-modal 
      id="renvoyer_signature_sms" ref="renvoyer_signature_sms" hide-footer centered title="Envoyer un Sms pour signature">
        <p class="mb-1">Etes vous sur de vouloir envoyer par sms au {{ renvoyerDataForm.indicatif + ' ' + renvoyerDataForm.telephone }} le lien pour signature du devis {{ renvoyerDataForm.numDevis }}</p>
        <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="sendSignatureLinkBySms" class="mr-2" :disabled="disableRenvoyer">
                Oui
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="$refs['renvoyer_signature_sms'].hide()">
                Non
              </b-button>
          </div>
      </b-modal>
      <!--  -->
      <div v-if="interfaceChosen === 'Listing'">
        <b-card v-if="can('List projects of broker') || can('List projects by commercial')" title="Filtres">
          <div class="custom-search">
            <!-- advance search input -->
            <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Période comprise entre le"
                    label-for="entrele"
                  >
                    <flat-pickr
                      id="entrele"
                      v-model="filtre.dateEffet_entre_le"
                      :config="configDate"
                      class="form-control"
                      placeholder="Date d'effet"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Et le" label-for="et_le">
                    <flat-pickr
                      id="et_le"
                      v-model="filtre.dateEffet_el_le"
                      :config="configDate"
                      class="form-control"
                      placeholder="Date d'effet"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Gestionnaire" label-for="commercial">
                    <b-form-select id="commercial" v-model="selectedCommercial" :options="commercialOptions" @change="getAllProjets(1)"
                                  text-field="nom" value-field="id"
                    >
                      <template #first>
                        <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Type de client" label-for="type-client">
                    <b-form-select id="type-client" v-model="selectedTypeClient" :options="optionsTypeClient" @change="getAllProjets(1)"/>
                  </b-form-group>
                </b-col>
              <b-col md="2">
                <b-form-group label="Risque" label-for="risque">
                  <b-form-select id="risque" v-model="selectedRisque" :options="risquesOptions" @change="getAllProjets(1)">
                    <template #first>
                      <b-form-select-option :value="null">
                        -- Choisissez --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Assureur" label-for="assureur" >
                  <b-form-select id="assureur" v-model="selectedAssureur" @change="getAllProjets(1)" :options="assureursOptions">
                    <template #first>
                      <b-form-select-option :value="null">
                        -- Choisissez --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <!-- <b-col md="3">
              <b-form-group
                  label="Avancement"
                  label-for="avancement"
              >
                <b-form-select
                    id="avancement"
                    v-model="selectedAvancement"
                    :options="avancementOptions"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      -- Choisissez --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col> -->
              <b-col md="2">
                <b-form-group label="Statut" label-for="statut">
                  <b-form-select id="statut" v-model="selectedStatut" :options="statutOptions" @change="getAllProjets(1)">
                    <template #first>
                      <b-form-select-option :value="null">
                        -- Choisissez --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Recherche" label-class="p-0">
                  <b-input-group>
                    <b-form-input v-model="recherche" :lazy="true" class="d-inline-block" type="text" @keyup.enter="getAllProjets(1)"
                      placeholder="Recherche"  />
                    <b-input-group-append is-text>
                      <feather-icon icon="SearchIcon" class="cursor-pointer" @click="getAllProjets(1)" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <!--            <b-form-group-->
                <!--                label="Recherche"-->
                <!--                label-for="recherche"-->
                <!--            >-->
                <!--            <b-input-group>-->
                <!--                  <b-form-input-->
                <!--                    v-model="recherche"-->
                <!--                    class="d-inline-block"-->
                <!--                    placeholder="Recherche"-->
                <!--                    type="text"-->
                <!--                  />-->
                <!--            <b-input-group-append>-->
                <!--                    <b-button-->
                <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                <!--                        variant="primary"-->
                <!--                        @click="searchInput"-->
                <!--                    >-->
                <!--                      <feather-icon-->
                <!--                          icon="CheckSquareIcon"-->
                <!--                          size="14" -->
                <!--                      />-->
                <!--                    </b-button>-->
                <!--                  </b-input-group-append>-->
                <!--                </b-input-group>-->
                <!--            </b-form-group>-->
              </b-col>
              <b-col class="mb-50" lg="1" md="1">
                <b-button v-b-tooltip.hover.top="'Tout afficher'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mt-0 mt-md-2" variant="primary" @click="clearAllFilter">
                  <feather-icon icon="RefreshCcwIcon" />
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card v-if="can('List projects of broker') || can('List projects by commercial')">
          <!-- table -->

           <button
              class="btn btn-primary mb-1"
              :data="json_data"
              :fields="json_fields"
              v-if="can('Export Projects') || currentUser.courtier_user[0].isPrincipal"
              worksheet="les devis"
              name="liste_devis.xls"
              @click="exportProjet"
            >
              <span>Exporter </span>
              <feather-icon class="mr-50" icon="DownloadIcon" />
          </button>
          <!-- <export-excel
          class   = "btn btn-primary mb-1"
          :data   = "json_data"
          :fields = "json_fields"
          worksheet = "les projets"
          v-if="can('Export Projects') || currentUser.courtier_user[0].isPrincipal"

          name    = "devis.xls">
              <span>Exporter </span>
              <feather-icon
                class="mr-50"
                icon="DownloadIcon"
              />
          </export-excel> -->
          <vue-good-table :columns="headers" :pagination-options="{
            enabled: true,
            perPage: pageLength
          }" :rows="rowsVGT" :rtl="direction" :search-options="{
            enabled: false,
          }" :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'lignes sélectionnées',
            clearSelectionText: 'Effacer la sélection',
            disableSelectInfo: false,
            selectAllByGroup: false
          }" :sort-options="{
            enabled: true,
          }" @on-selected-rows-change="selectionChanged">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'projetNum'" @click="showProjet(props.row.allProjet.id, props.row.sourceName)">
                <a style="text-decoration: underline;">{{ props.row.projetNum }} </a>
              </div>
              <div v-else-if="props.column.field == 'clientName'"
                @click="showProspect(props.row.allProjet.devisable.id, props.row.allProjet.devisable.categorie, props.row.allProjet.devisable.type)">
                <a style="text-decoration: underline;">{{ props.row.clientName }} </a>
              </div>
              <div v-else-if="props.column.field == 'sourceName'" class="text-uppercase">
                <b-badge :variant="sourceVariant(props.row.sourceName)"> {{ props.row.sourceName }}</b-badge>
              </div>
              <div v-else-if="props.column.field == 'actions'">
                <span>
                  <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16" />
                    </template>
                    <b-dropdown-item @click="showProjet(props.row.allProjet.id, props.row.sourceName)">
                      <feather-icon class="mr-50" icon="Edit2Icon" />
                      <span>Consulter la fiche devis</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="props.row.allProjet.devis_avancement == 'TARIFICATION' && props.row.allProjet.statut !== 'Devis cloture sans suite' 
                      && props.row.allProjet.statut !== 'En attente signature' && props.row.allProjet.statut !== 'Refuse par le client'
                      && props.row.sourceName === 'Interne' && can('Update project intern')  && props.row.statusGDA != 'ROUGE' && props.row.statusGDA != 'ORANGE' "
                      @click="redirectprojet(props.row.allProjet)">
                      <feather-icon class="mr-50" icon="EditIcon" />
                      <span>Finaliser</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else-if="props.row.sourceName === 'Externe' && can('Update project extern') && props.row.allProjet.statut !== 'Expire'"
                      v-b-toggle.sidebar-2 @click="updateProjectExterne(props.row.allProjet)">
                      <feather-icon class="mr-50" icon="EditIcon" />
                      <span>Modifier</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else-if="props.row.allProjet.devis_avancement == 'SOUSCRIPTION' && props.row.allProjet.statut !== 'Devis cloture sans suite' 
                      && props.row.allProjet.statut !== 'En attente signature' && props.row.allProjet.statut !== 'Refuse par le client' && props.row.allProjet.devis_etape_avancement !== 'devisenregistre'
                      && props.row.sourceName === 'Interne' && can('Subscribe pricing') && props.row.statusGDA != 'ROUGE' && props.row.statusGDA != 'ORANGE'"
                      @click="redirectprojet(props.row.allProjet)">
                      <feather-icon class="mr-50" icon="CheckCircleIcon" />
                      <span>Souscrire</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else-if="props.row.allProjet.devis_avancement == 'SOUSCRIPTION' && props.row.allProjet.devis_etape_avancement == 'devisenregistre'
                      && props.row.sourceName === 'Interne' && props.row.statusGDA != 'ROUGE' && props.row.statusGDA != 'ORANGE'"
                      @click="redirectExtranet(props.row.allProjet)">
                      <feather-icon class="mr-50" icon="ExternalLinkIcon" />
                      <span>Accéder à l'extranet</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-toggle.sidebar-3
                      v-if="props.row.allProjet.statut !== 'Refuse par le client' &&  props.row.allProjet.statut !== 'Expire'"
                      @click="joindreFile(props.row.allProjet.etude_id, props.row.allProjet.id, props.row.allProjet.devisable_id, props.row.allProjet.devisable.type)">
                      <feather-icon class="mr-50" icon="LinkIcon" />
                      <span>Joindre</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="JoindreContrat(props.row.allProjet.id, props.row.sourceName)">
                      <feather-icon class="mr-50" icon="SearchIcon" />
                      <span>Visualiser</span>
                    </b-dropdown-item>

                     <b-dropdown-item v-b-modal.modal_1 @click="attachCommercial(props.row.allProjet)">
                        <feather-icon
                            class="mr-50"
                            icon="Link2Icon"
                        />
                        <span>Attacher à un gestionnaire</span>
                      </b-dropdown-item>

                    <b-dropdown-item v-if="props.row.sourceName != 'Externe'"
                      @click="dupliquerDevis(props.row.allProjet.id, props.row.allProjet.statut)">
                      <feather-icon class="mr-50" icon="CopyIcon" />
                      <span>Dupliquer</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteDevis(props.row.allProjet.id)">
                      <feather-icon class="mr-50" icon="TrashIcon" />
                      <span>Supprimer</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="props.row.sourceName === 'Externe'"
                      @click="AddContratTransformermodel(props.row.allProjet.id,formatDateAdd(props.row.dateEffet), props.row.allProjet.createur_id, props.row.allProjet.origine)">
                      <feather-icon class="mr-50" icon="PlusIcon" />
                      <span>Transformer en contrat</span>
                    </b-dropdown-item>

                   

                    <b-dropdown-item
                      v-if="can('Update project extern') && props.row.allProjet.statut !== 'Devis cloture sans suite' && can('Update project intern')"
                      @click="classerDevis(props.row.allProjet.id)">
                      <feather-icon class="mr-50" icon="ArchiveIcon" />
                      <span>Classer sans suite</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.renvoyer_signature v-if="props.row.statutProjet === 'En attente signature' && props.row.sourceName === 'Interne' && checkDateEnvoiSignValide(props.row.allProjet.date_envoi_signature)"  @click="fillRenvoyerSignatureModalData(props.row.allProjet)">
                        <feather-icon
                            class="mr-50"
                            icon="SendIcon"
                        />
                        <span>Renvoyer pour signature</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-b-modal.renvoyer_signature_sms v-if="props.row.statutProjet === 'En attente signature' && props.row.sourceName === 'Interne' && checkDateEnvoiSignValide(props.row.allProjet.date_envoi_signature)"  @click="fillRenvoyerSignatureModalData(props.row.allProjet)">
                        <feather-icon
                            class="mr-50"
                            icon="MessageSquareIcon"
                        />
                        <span>Envoyer un Sms pour signature</span>
                    </b-dropdown-item>

                  </b-dropdown>
                </span>
              </div>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1"
                      @input="(value) => {
                        getAllProjets(1)
                        props.perPageChanged({ currentPerPage: value })
                      }" />
                    <span class="text-nowrap"> de {{ allDataFprFetch.total }} entrées </span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2 align-items-center">
                <b-col md="4">
                  <b-input-group>
                    <b-form-select v-model="selected_action" :options="options" />
                    <b-input-group-append>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                        @click="action_masse(selected_action)">
                        <span>Valider </span>
                        <feather-icon class="mr-50" icon="ArrowRightIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col md="8">
                  <b-pagination :per-page="pageLength" 
                    :total-rows="allDataFprFetch.total"
                    :value="allDataFprFetch.current_page ? allDataFprFetch.current_page : 1 "
                    align="right" first-number
                    last-number next-class="next-item" prev-class="prev-item"
                    @input="value => {
                      if (pagepagination != value) {
                        getAllProjets(value);
                        pagepagination = value;
                        
                      }
                  }">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </template>
            <div slot="emptystate" class="text-center align-middle">
              <span>Aucune donn&eacute;e disponible dans le tableau</span>
            </div>
          </vue-good-table>
        </b-card>
      </div>
      <b-modal id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter" centered size="lg">
        <autres-document :devis-id-doc="joindre_id_contrat" :devis-type-doc="joindre_type_contrat" />
      </b-modal>
    </div>
     <b-modal
            id="modal_1"
            hide-footer
            centered
            title="Attacher à un gestionnaire"
          >
          <b-form
                class="p-2"
                @submit.prevent="commercialClientSave"
              >
            <b-form-group label="Gestionnaire" label-for="commercial">
               <b-form-select
                      v-model="attached_commercial"
                      id="commercial"
                    >
                <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
                <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}</b-form-select-option>
               </b-form-select>
            </b-form-group>
            <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="attached_commercial == null || (clientsAttacher.length == 1 && clientsAttacher[0].actif_commercial == attached_commercial)"
                  >
                    Attacher
                  </b-button>
            </div>
          </b-form>
          </b-modal>
    <b-modal id="transforme-contrat" ref="transforme-contrat" hide-footer centered hide-header-close>
      <b-overlay
                  :show="showLoading"
                  no-wrap
              />
        <validation-observer ref="formContratTransformer">
          <b-form class="p-2" @submit.prevent="AddContratTransformer">
              <img class="rounded mx-auto d-block" src="../../assets/images/be-careful-6-128.png" alt="Icone warnnig">
            <h1 style="margin-top:30px; text-align: center;">Etes-vous sûr de vouloir transformer ce devis en contrat ?</h1>

            <b-form-group label="N° contrat*" label-for="numcontrat">
              <validation-provider #default="{ errors }" name="numcontrat" rules="required">
                <b-form-input id="num_contrat" v-model="contrat.num_contrat" :disabled="isUpdate"
                              :state="errors.length > 0 ? false : null" placeholder="N° contrat"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date d'effet*" label-for="date-effet">
              <validation-provider #default="{ errors }" name="date d'effet" rules="required">
                <flat-pickr id="date-effet" v-model="contrat.date_effet" class="form-control"
                  placeholder="Date d'effet" :config="configDateEffetTransform" autocomplete="off"
                  :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          
            <div class="d-flex mt-2" style="margin: auto; width: 50%; padding: 10px;">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Oui
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-2" @click="resetModall">
                Non
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    <div v-if="interfaceChosen === 'FicheProjet'">
      <parent-fiche-projet @back-to-listing="backToListing" :projet-chosen="projetChosen"
       @supprimer-projet="deleteDevis"
       @update-projet-externe="updateProjectExterne"
       @classer-sans-suite="classerDevis"
       @souscrire-projet="redirectprojet"
       @redirect-externe-projet="redirectExtranet"
       @finaliser-projet="redirectprojet"
       @add-contrat-tranform="AddContratTransformermodel"
       @dupliquer-devis="dupliquerDevis"
       @show-prospect="showProspect"
       :isProjectUpdated="isProjectUpdated"
       :isDocumentAdded="isDocumentAdded"
       @initial-docs-refreshed="recordDocumentReturned"
       @ajouter-document="joindreFile"
       @updated-isprojet-data="updatedIsprojetData"
       @fill-renvoyer-signature-modal-data="fillRenvoyerSignatureModalData"
       />
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  VBToggle,
  VBTooltip,
  BFormInvalidFeedback,
  BFormRadioGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import ImportNewProjet from './ImportNewProjet.vue'
import moment from 'moment'
import ImportNewDocProjet from '@/views/projets/ImportNewDocProjet'
import AutresDocument from '@/views/projets/AutresDocument'
import ParentFicheProjet from '@/views/projets/projet/ParentFicheProjet.vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { props } from 'vue-prism-component'
import vSelect from 'vue-select'

export default {
  components: {
    BBadge,
    BInputGroup,
    VBTooltip,
    BOverlay,
    ImportNewProjet,
    BInputGroupAppend,
    VueGoodTable,
    BAvatar,
    BForm,
    BFormSelectOption,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    flatPickr,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BModal,
    BImg,
    ValidationProvider,
    ValidationObserver,
    ImportNewDocProjet,
    ParentFicheProjet,
    AutresDocument,
    BFormInvalidFeedback,
    ToastificationContent,
    vSelect,
    BFormRadioGroup
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      pagepagination:1,
      page:1,
      attached_commercial: null,
      clientsAttacher: [],
      json_fields: {
            'N° devis': 'projetNum',
            'Client': 'clientName',
            'Risque': 'risqueName',
            'Assureur': 'assureurName',
            'Statut': 'statutProjet',
            'Source': 'sourceName',
            'Date d\'effet': 'dateEffet',
            'Prime TTC': 'budgetProjet',
            'Gestionnaire': 'commecrialName',
        },
        json_data: [],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],

      devisidfortransformcontrat :null,
      contrat:{
        date_effet: null,
        num_contrat: null,
        source:null,
        createur_id: null,
        origine: null
      },
      configDate: {
        dateFormat: "d/m/Y",
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if (
              (val.length == 2 || val.length == 5) &&
              ele.getAttribute("id")
            ) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      configDateEffetTransform: {
          dateFormat: 'Y/m/d',
          altFormat: 'd/m/Y',
          altInput: true,
          // disable:[{from:moment(new Date(), "DD/MM/YYYY"),to:'25/02/3000'}],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      filtre: {
        dateEffet_el_le: null,
        dateEffet_entre_le: null,
      },
      projetsCount: 0,
      interfaceChosen: 'Listing',
      projetChosen: {
        id: null,
        type: null
      },
      commercialOptions:[],
      
      isDocumentAdded: false,
      isProjectUpdated: false,
      recherche: null,
      hideFooter: false,
      selected_date_effet: null,
      selected_devisId: null,
      title_modal: '',
      msg_modal: '',
      etude_id_joindre: null,
      devis_id_joindre: null,
      client_id_joindre: null,
      client_type_joindre: null,
      joindre_id_contrat: null,
      joindre_type_contrat: null,
      allDataFprFetch: {},
      currentUser: JSON.parse(localStorage.getItem('userData')),
      configDateEffet: {
          dateFormat: 'Y-m-d',
          altFormat: 'd-m-Y',
          altInput: true,
          // disable:[{from:moment(new Date(), "DD/MM/YYYY"),to:'25/02/3000'}],
          disable: [ function(date) {
              let localDay=new Date()
              let firstDayCurrentMonth = new Date(localDay.getFullYear(), localDay.getMonth(), 1) 
              return date < firstDayCurrentMonth
              // return date < localDay.setDate(localDay.getDate()-1)
          }],
          allowInput: true,
          onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement;
              var val = ele.value;

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += "/";
              }
              ele.value = val;
              ele.dispatchEvent(new Event("input"));
            }
          },
      },
      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer '
        },
        {
          value: 'Classer',
          text: 'Classer sans suite'
        }
        // { value: 'Souscrire', text: 'Souscrire' },
        // { value: 'Editer', text: 'Editer' },
        // { value: 'Joindre', text: 'Joindre' },
        // { value: 'Supprimer', text: 'Supprimer' }
      ],
       optionsTypeClient: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: 'PARTICULIER',
          text: 'Particulier'
        },
        {
          value: 'PROFESSIONNEL',
          text: 'Professionnel'
        },
        {
          value: 'ENTREPRISE',
          text: 'Entreprise'
        }
      ],

      selected_action: null,
      showLoading: false,
      devis_id: null,
      devis_name: null,
      dir: false,
      pageLength: 10,
      indexOrg: null,
      indexLigne: null,
      projetLocal: [],
      localidtable: this.id_projet,
      selectedCommercial:null,
      selectedTypeClient:null,
      projetForUpdate: {
        idEtude: null,
        idDevis: null,
        client: null,
        risque: null,
        assureur: null,
        statut: null,
        num_devis: null,
        date_effet: null,
        date_creation: null,
        budget_propose: null,
        budget_actuel: null,
        variation: null,
        courtier_id: null,
        user_id: null,
        origineautre:null,
        origine:null,
        createur:null,
        intermediaire: null,
        user:null,
      },
      headers: [
        {
          label: 'N° devis',
          field: 'projetNum',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Client',
          field: 'clientName',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Risque',
          field: 'risqueName',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Assureur',
          field: 'assureurName',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        // {
        //   label: 'Avancement',
        //   field: 'avancementDevis',
        //   sortable: true,
        //   thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
        //   tdClass: 'text-center m-0 p-1 align-middle h6',
        // },
        {
          label: 'Statut',
          field: 'statutProjet',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Source',
          field: 'sourceName',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'dateEffet',
          sortable: false,
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Prime TTC',
          field: 'budgetProjet',
          type: 'decimal',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Gestionnaire',
          field: 'commecrialName',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
        },
        // { label: 'Projet', field: 'etude', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'modifier le', field: 'modifie_le', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Date de création', field: 'date_creation', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Commercial', field: 'commercial', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      ],
      projetsList: [],
      // naturedocument: [],
      projet: [],
      list_client_personne_physique: [],
      list_client_personne_morale: [],
      // list_commercial: [],
      // list_types_doc: [],
      projetfill: [],
      searchTerm: '',

      selectedRisque: null,
      selectedAssureur: null,
      selectedAvancement: null,
      selectedStatut: null,

      prospectData: [],
      rowsVGT: [],
      risquesOptions: [],
      assureursOptions: [],
      avancementOptions: [
        {
          value: 'SOUSCRIPTION',
          text: 'Souscription'
        },
        {
          value: 'TARIFICATION',
          text: 'Tarification'
        },
      ],
      source: [{
        1: 'EXTERNE',
        2: 'INTERNE',
      },
        {
          1: 'light-primary',
          2: 'light-warning'
        }],
      statutOptions: [
        {
          value: 'Etude',
          text: 'Etude'
        },
        {
          value: 'Devis envoye',
          text: 'Devis envoyé'
        },
        {
          value: 'En attente signature',
          text: 'En attente signature'
        },
        {
          value: 'Devis cloture sans suite',
          text: 'Devis clôturé sans suite'
        },
        {
          value: 'Refuse par le client',
          text: 'Refusé par le client'
        },
        {
          value: 'Expire',
          text: 'Expiré'
        },
        {
          value: 'SIMULAT_EDITEE',
          text: 'Simulation éditée'
        }
      ],
      renvoyerDataForm:{
        email:"",
        telephone:"",
        indicatif:"",
        idDevis:"",
        idMoyContact:"",
        nomClient:"",
        prenomClient:"",
        numDevis:"",
        lien_signature:""
      },
      indicatif:[],
      disableRenvoyer:false,
      options_lien_signature:[
        {
          value: 'resend',
          text: 'Renvoyer le lien actuel'
        },
        { value: 'new', text: 'Créer un nouveau lien'},

      ]

    }
  },
  validations: {
    projetGlobInfos: {
      nomprojet: { required },
      client: { required },
      commercial: { required },
    },
    selectedrows_masse: [],
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    computed_en_cours() {
      const resultprojet = this.projetLocal.filter(item => item.statut == 'En cours')
      const ret = {}
      for (const i in resultprojet) {
        const key = resultprojet[i].id_etude
        ret[key] = {
          etude: key,
          count: ret[key] && ret[key].count ? ret[key].count + 1 : 1,
        }
      }
      return Object.values(ret).length
    },
    computed_historique() {
      const resultprojet = this.projetLocal.filter(item => item.statut != 'En cours' && item.statut != 'Contrat emis')
      //   var ret = {}
      //   for (let i in resultprojet) {
      //     let key = resultprojet[i].id_etude
      //     ret[key] = {
      //       etude: key,
      //       count: ret[key] && ret[key].count ? ret[key].count + 1 : 1
      //     }
      //   }
      //   return Object.values(ret).length
      return resultprojet.length
    },
    computed_contrat_emis() {
      const resultprojet = this.projetLocal.filter(item => item.statut == 'Contrat emis')
      const ret = {}
      for (const i in resultprojet) {
        const key = resultprojet[i].id_etude
        ret[key] = {
          etude: key,
          count: ret[key] && ret[key].count ? ret[key].count + 1 : 1,
        }
      }
      return Object.values(ret).length
    },
    computed_tous() {
      const resultprojet = this.projetLocal
      // var ret = {}
      // for (let i in resultprojet) {
      //     let key = resultprojet[i].id_etude
      //     ret[key] = {
      //     etude: key,
      //     count: ret[key] && ret[key].count ? ret[key].count + 1 : 1
      //     }
      // }
      // return Object.values(ret).length;
      return resultprojet.length
    },
    getTitleRenvoyerSignatureModal(){
      return `Renvoyer pour signature - Devis ${this.renvoyerDataForm.numDevis} - ${this.renvoyerDataForm.nomClient}`
    }
  },
  watch: {
    'filtre.dateEffet_entre_le':{
      handler(val) {
        if (val != null && val != '')
          this.getAllProjets(1)
      },
      deep: true
    },
    'filtre.dateEffet_el_le':{
      handler(val) {
        if (val != null && val != '')
          this.getAllProjets(1)
      },
      deep: true
    },
    projetfill: {
      handler(val) {
        // if(this.selectedStatut != 'Devis cloture sans suite')
        // {
        //   this.projetsCount = val.filter(item => item.statut != 'Devis cloture sans suite' && item.devis_etape_avancement != 'signaturevalide').length
        // }
        // else
        // {
        //   this.projetsCount = val.filter(item => item.devis_etape_avancement != 'signaturevalide').length
        // }
        this.rowsVGT = []
        
        val.forEach(item => {

          this.rowsVGT.push({
            'projetNum': item.etude.source === 'Interne' ? item.devis_no_interne : item.devis_no_externe,
            'clientName': item.devisable.type === 'PERSONNE_PHYSIQUE' ? item.devisable.nom + ' ' + item.devisable.prenom : item.devisable.denomination_commercial,
            'risqueName': item.risque.label,
            'assureurName': item.assureur !== null ? item.assureur.nom_com_assureur : null,
            'avancementDevis': item.devis_etape_avancement ? item.devis_etape_avancement : '--',
            'statutProjet': item.statut ? this.statutOptions.find(itemStatut => item.statut === itemStatut.value)?.text : null,
            'sourceName': item.etude.source,
            'dateEffet': (item.risque.label === 'Emprunteur' && item.devis_objets.length > 0) ? this.changeFormatDate(item.devis_objets[0].objet.objeable.prets[0].dateEffet) : (item.risque.label && item.risque.label !== 'Emprunteur') ? this.changeFormatDate(item.date_effet) : null,
            'budgetProjet': item.etude.source === 'Interne' ? this.$_.get(item.tarification.find(item => item.preconise == 1), 'prime', null) : item.prime,
            'commecrialName': item.user_id ? item.user.personne_physique.prenom + ' ' + item.user.personne_physique.nom : '',
            'allProjet': item,
            'statusGDA': item.devisable.statut_gda,

          })
        })
           
      },
      deep: true
    },
    isactive() {
      this.projetLocalFiltred = []
      this.projetLocal = []
      this.reloadprojet()
      // this.projetfill = this.projet;
      if (this.isactive === 'projet') {
        this.resetDataProjetGlobInfos()
        if (this.projetfill.length > 0) {
          this.pushAndFusiondata()
        }
        this.projetLocalFiltred = this.projetLocal
      }
    },
  },
  created() {
    
  },
  mounted() {
    this.$http.get(`tiers/getPays`)
      .then((r) => {
        let france = r.data.find(item => item.indicatif == "+33")
        r.data.sort(function(x,y){ return x == france ? -1 : y == france ? 1 : 0; })
        this.indicatif = r.data
      })
      .catch((e)=>{
        console.log(e);
      })
    this.getAll()
    this.fetchDataCommercialByCourtier()
    this.hideFooter = true
    const qp = new URLSearchParams(window.location.search)
    const devis_id = qp.get('projet') ? cryptanddecypt(qp.get('projet'), 'decrypt') : null
    const etude_id = qp.get('etude') ? cryptanddecypt(qp.get('etude'), 'decrypt') : null
    if (devis_id && etude_id) {
      const get_projet = this.projetLocal.find(el => el.id_etude === etude_id)
      this.updateprojet(get_projet)
    }

    if (this.$route.params.id && this.$route.params.type && this.$route.params.interfaceChosen){
      this.projetChosen.id = this.$route.params.id
      this.projetChosen.type = this.$route.params.type
      this.interfaceChosen = 'FicheProjet'
    }

    this.getAllProjets()
  },
  methods: {
    attachCommercial(data) {
      this.clientsAttacher.splice(0);
      this.attached_commercial = data.user_id
      this.clientsAttacher.push({
        actif_devis: data.id,
        actif_commercial: data.user_id
      })

    },
     commercialClientSave() {

      this.$http.post("devis/attachementDevis/", { 'devis_id': this.clientsAttacher[0].actif_devis, 'user_id': this.attached_commercial })
        .then((res) => {

          if (res.data.success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Le client a été attaché avec un nouveau commercial`,
                  icon: "UserIcon",
                  variant: "success",
                },
              },
              {
                position: "top-right",
                timeout: 4000,
              }
            );
            this.$bvModal.hide('modal_1')
            this.getAllProjets(1)

          }
        })
        .catch((err) => { });
    },
     downloadDocument(blob, name) {
      if (blob) {
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}_${moment().format("YYYYMMDDHHmm")}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    // { "type_list": "CLIENT", "filter": { } }
    exportProjet() {
      const formData = new FormData()       
      formData.append('type_list','DEVIS')
      console.log(this.filterData())
      const filterDataJson = JSON.stringify(this.filterData())
      console.log(filterDataJson)
      formData.append('filter', filterDataJson)

      const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: "blob" }
      this.$http
        .post(`/portefeuilles/exportPagination`, formData, config)
        .then((response) => {
          this.downloadDocument(response.data, 'list devis')
        })
        .catch((error) => {
          console.error(error);

          // Display an error message
          this.messageToast('Une erreur a été survenue lors du téléchargement.', 'Erreur', 'warning');
        });
    },
    getAllProjets(page = 1) {
      this.page =  page
      this.showLoading = true
      this.$http
        .post('/portefeuilles/getDataPagination?page=' + page, {
          type_list: 'DEVIS',
          per_page: this.pageLength,
          filter:this.filterData()
        })
        .then(res => {
          if (res.data.success) {
            this.allDataFprFetch = res.data.data
            this.projet = res.data.data.data
            this.projetfill = this.projet


            this.showLoading = false
          }
        })
        .catch(err => {
          this.showLoading = false
          this.json_data = [];
          console.error(err)
        })
      
    },
    resetModall() {
      this.contrat.date_effet = null
      this.contrat.num_contrat = null
      this.contrat.origine = null
      this.contrat.createur_id = null
      this.$refs['transforme-contrat'].hide()
    },
    formatDateAdd(date) {
      return date ? moment(date, 'DD/MM/YYYY')
      .format('YYYY/MM/DD') : ''
    },
    AddContratTransformer(){
      this.$refs.formContratTransformer.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              const formData = new FormData()
      
              formData.append('devis_id', this.devisidfortransformcontrat)
              formData.append('police_no',this.contrat.num_contrat)
              formData.append('date_effet', this.contrat.date_effet)
              formData.append('createur_id',this.contrat.createur_id)
              formData.append('origine', this.contrat.origine)

              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              this.$http
              .post('/contrat/addContratTransform', formData, config)
              .then(res => {
                if (res.data.success) {
                  
                  this.$router.push({ name: 'contrats-list', params: { id: res.data.data.id ,type:"EXTERNE",interfaceContrat:"FicheContrat"} })
                  this.messageToast('Contrat a été bien ajouter', 'Succès', 'success')
                  this.resetModall()
                  this.showLoading = false

                }
              })
              .catch(err => {
                this.showLoading = false
                this.messageToast(err.response.data.errors[0], 'Erreur', "danger")
                console.error(err)
              })
              this.resetModal()
             
            }
          })
    },
    AddContratTransformermodel(id,date, createur, origine){
      this.devisidfortransformcontrat = id
      this.contrat.date_effet = date
      this.contrat.createur_id = createur
      this.contrat.origine = origine
      this.$refs['transforme-contrat'].show()
    },
    backToListing(value) {
      this.interfaceChosen = value
    },
    recordDocumentReturned(isAdded) {
      this.isDocumentAdded = isAdded
    },
    updatedIsprojetData(value) {
      this.isProjectUpdated = value
    },
    getAll() {
      this.$http
        .get('/projet/getallProjets')
        .then(res => {
          // this.projet = res.data.projet
          this.list_client_personne_physique = res.data.list_client_personne_physique
          this.list_client_personne_morale = res.data.list_client_personne_morale
          // this.list_commercial = res.data.list_commercial
          // this.list_types_doc = res.data.list_types_doc
          this.fetchAssureurList(res.data.list_assureur)
          this.fetchRisqueList(res.data.list_risque)
          // this.projetfill = this.projet
          this.prospectData = this.list_client_personne_physique.concat(this.list_client_personne_morale)
          
          this.prospectData = this.$_.orderBy(this.prospectData, [client => client.nameClient.toLowerCase()], ['asc'])

          // if (this.projetfill.length > 0) {
          //   this.pushAndFusiondata()
          // }
          // this.projetLocalFiltred = this.projetLocal
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetModal() {
      this.selected_date_effet = null
      this.selected_devisId    = null
    },
    sourceVariant(value) {
      const statusColor = {
        Externe: 'light-primary',
        Interne: 'light-warning',
      }
      return statusColor[value]
    },
    dupliquerDevis(idDevis, statutDevis) {
        this.$swal({
          html: statutDevis === 'Devis cloture sans suite' ? 'La duplication se fera avec les dernières données du CRM.<br> Elles ne seront pas reprise de ce devis si elles ont changé entre temps.<br> Souhaitez-vous poursuivre la duplication ?' : 'Souhaitez-vous classer le devis , d\'origine, sans suite ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        })
        .then(result => {
          let data = {
            devisId: idDevis,
            dateEffet: null,
            action: ''
          }
          if(result.value) {
            data.action = 'duplicateWithArchive'
            this.recordDuplicateProjet(data)
          } else if(result.dismiss === 'cancel') {
            if(statutDevis != 'Devis cloture sans suite') {
              data.action = 'duplicate'
              this.recordDuplicateProjet(data)
            }
          }
        });
    },
    deleteDevis(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce devis ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.$http
                  .delete(`/devis/deleteDevis/${id}`)
                  .then(res => {
                    for (let i = 0; i < this.projetfill.length; i++) {
                      if (this.projetfill[i].id == id) {
                        this.projetfill.splice(i, 1)
                        break
                      }
                    }
                    for (let i = 0; i < this.projet.length; i++) {
                      if (this.projet[i].id == id) {
                        this.projet.splice(i, 1)
                        break
                      }
                    }
                    this.interfaceChosen = 'Listing'
                    this.messageToast(res.data.message, 'Succès', 'success')
                  })
                  .catch(error => {
                    console.log(error)
                    this.messageToast(error.response.data, 'Erreur', "danger")
                  })
            }
          })
    },
    classerDevis(id){
      this.$swal({
              title: 'Êtes-vous sûr(e) de vouloir clôturer ce devis sans suite ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
            .then((result) => {
              if (result.value) {
                this.$http
                .post(`/devis/classerDevis`,{id:id})
                .then(res => {
                  for (let i = 0; i < this.projetfill.length; i++) {
                    if (this.projetfill[i].id == id) {
                      this.projetfill[i].statut= "Devis cloture sans suite"
                    }
                  }
                  for (let i = 0; i < this.projet.length; i++) {
                    if (this.projet[i].id == id) {
                      this.projet[i].statut= "Devis cloture sans suite"
                    }
                  }
                  this.interfaceChosen = 'Listing'
                  this.$swal({
                      icon: 'success',
                      title: res.data.message,
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                })
                .catch(error => {
                  console.log(error)
                  this.messageToast(error.response.data, 'Erreur', "danger")
                })
              }
            })
    },
    action_masse(action) {
      switch (action) {
        case 'Supprimer':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              title: 'Êtes-vous sûr de vouloir supprimer les projets ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    this.$http
                        .post('devis/devisDeleteMultiple/', { 'devis': this.selectedrows_masse.selectedRows })
                        .then((res) => {
                          if (res.data.success) {
                            for (let i = 0; i < this.projetfill.length; i++) {
                              for (let j = 0; j < res.data.deleted.length; j++) {
                                if (this.projetfill[i].id == res.data.deleted[j].id) {
                                  this.projetfill.splice(i, 1)
                                }
                              }
                            }
                            for (let i = 0; i < this.projet.length; i++) {
                              for (let j = 0; j < res.data.deleted.length; j++) {
                                if (this.projet[i].id == res.data.deleted[j].id) {
                                  this.projet.splice(i, 1)
                                }
                              }
                            }
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: 'Supprimé!',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
          }
          break
        case 'Classer':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              title: 'Êtes-vous sûr(e) de vouloir clôturer ces projets sans suite ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
                .then((result) => {
                  if (result.value) {
                    this.$http
                        .post('devis/devisClasserMultiple/', { 'devis': this.selectedrows_masse.selectedRows })
                        .then((res) => {
                          if (res.data.success) {

                            for (let i = 0; i < this.projetfill.length; i++) {
                              for (let j = 0; j < res.data.classed.length; j++) {
                                if (this.projetfill[i].id == res.data.classed[j].id) {
                                  this.projetfill[i].statut = "Devis cloture sans suite"
                                }
                              }
                            }
                            for (let i = 0; i < this.projet.length; i++) {
                              for (let j = 0; j < res.data.classed.length; j++) {
                                if (this.projet[i].id == res.data.classed[j].id) {
                                  this.projetfill[i].statut= "Devis cloture sans suite"
                                }
                              }
                            }

                            
                          }
                        })
                        .catch((err) => {
                        })

                    this.$swal({
                      icon: 'success',
                      title: ' Projets clôturés sans suite',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  }
                })
          }
          break
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params
    },
    joindreFile(etude, devis, clientId, clientType, fromChild = false) {
      if(fromChild) 
        this.$root.$emit('bv::toggle::collapse', 'sidebar-3')
      
      this.etude_id_joindre = etude
      this.devis_id_joindre = devis
      this.client_id_joindre = clientId
      this.client_type_joindre = clientType
    },
    JoindreContrat(devisId, devisType) {
      this.joindre_id_contrat   = devisId
      this.joindre_type_contrat = devisType
      if (this.joindre_id_contrat && this.joindre_type_contrat) {
        this.$refs['modal-display-document'].show()
      }
    },
    clearDataProjetUpdate() {
      this.projetForUpdate.idEtude = null
      this.projetForUpdate.idDevis = null
      this.projetForUpdate.client = null
      this.projetForUpdate.risque = null
      this.projetForUpdate.assureur = null
      this.projetForUpdate.statut = null
      this.projetForUpdate.num_devis = null
      this.projetForUpdate.date_effet = null
      this.projetForUpdate.date_creation = null
      this.projetForUpdate.budget_propose = null
      this.projetForUpdate.budget_actuel = null
      this.projetForUpdate.courtier_id = null
      this.projetForUpdate.user_id = null
      this.projetForUpdate.origine = null
      this.projetForUpdate.createur = null
      this.projetForUpdate.intermediaire = null
      this.projetForUpdate.user = null

    },
    updateProjectExterne(data, fromChild = false) {
      if(fromChild) {
        this.showLoading = true
        this.clearDataProjetUpdate()
          this.$http.get(`/projet/get_list_projet_by_devis_id/${data.id}`)
          .then(res => {
            this.isProjectUpdated = false
            this.showLoading = false
            this.transformDataProjetUpdate(res.data)
            
            this.$root.$emit('bv::toggle::collapse', 'sidebar-2')
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.transformDataProjetUpdate(data)
      }
    },
    transformDataProjetUpdate(data) {
      this.projetForUpdate.idEtude = data.etude.id
      this.projetForUpdate.idDevis = data.id
      this.projetForUpdate.client = data.devisable
      this.projetForUpdate.risque = data.risque_id
      this.projetForUpdate.assureur = data.assureur_id
      this.projetForUpdate.statut = data.statut
      this.projetForUpdate.num_devis = data.devis_no_externe
      this.projetForUpdate.date_effet = data.date_effet
      this.projetForUpdate.date_creation = data.date_creation
      this.projetForUpdate.budget_propose = data.prime
      this.projetForUpdate.budget_actuel = data.prime_actuel
      this.projetForUpdate.courtier_id = data.courtier_id
      this.projetForUpdate.user_id = data.user_id

      if (data.origine != null) {
        if (data.origine == 'Co_courtage' || data.origine == 'Indicateur_affaires' || data.origine == 'Weedo_market' || data.origine == 'Recommandation' || data.origine == 'Réseaux_sociaux') {
          this.projetForUpdate.origine = data.origine
        } else {
          this.projetForUpdate.origine = 'Autre'
          this.projetForUpdate.origineautre = data.origine
        }
      }else{
        this.projetForUpdate.origine = null
      }
      
      if (data.createur_devis === null) {
        this.projetForUpdate.createur = null
      } else {
        this.projetForUpdate.createur = {
          id: data.createur_devis.user.personne_physique.user_id,
          nom: `${data.createur_devis.user.personne_physique.prenom} ${data.createur_devis.user.personne_physique.nom}`,
          type: data.createur_devis.user.personne_physique.type
        };
      }

      if (data.user === null) {
        this.projetForUpdate.user = null
      } else {
        this.projetForUpdate.user = {
          id: data.user.personne_physique.user_id,
          nom: `${data.user.personne_physique.prenom} ${data.user.personne_physique.nom}`,
          type: data.user.personne_physique.type
        };
      }

      this.projetForUpdate.intermediaire = [];
      if (data.courtiers_users_by_intermediares && data.courtiers_users_by_intermediares.length > 0) {
        data.courtiers_users_by_intermediares.forEach(courtier => {
          this.projetForUpdate.intermediaire.push({
            id: courtier.user.personne_physique.user_id,
            nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
            type: courtier.user.personne_physique.type
          });
        });
    }
      
      
     
    },
    changeFormatDate(date) {
      return date ? moment(date)
          .lang('fr')
          .format('L') : null
    },
    RecordProjetExterneReturned(data, idCreated, fromChild = false) {
      if (idCreated) {
        this.projet.push(data);
        this.projetfill = this.projet
        // location.reload();
      } else {
        const indexProjetFill = this.projetfill.findIndex(el => el.etude.id === data.etude.id && el.id === data.id)
        this.projetfill.splice(indexProjetFill, 1)
        this.projetfill.unshift(data)

        const indexProjet = this.projet.findIndex(el => el.etude.id === data.etude.id && el.id === data.id)
        this.projet.splice(indexProjet, 1)
        this.projet.unshift(data)
        if(fromChild) {
          this.updateProjectExterne(data)
          this.isProjectUpdated = true
        }
      }
    },
    // fetch Data
    fetchRisqueList(data) {
      this.risquesOptions = []
      data.forEach(el => {
        this.risquesOptions.push({
          value: el.id,
          text: el.label,
        })
      })
    },
    fetchAssureurList(data) {
        this.assureursOptions = []
      data.forEach(el => {
        this.assureursOptions.push({
          value: el.id,
          text: el.nom_com_assureur.toUpperCase(),
        })
      })
    },
   fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.recherche != null) {
          this.searchTerm = this.recherche
        }
        this.showLoading = false
      }, 500);
    },
    resetDataProjetGlobInfos() {
      this.projetGlobInfos.nomprojet = null
      this.projetGlobInfos.client = null
      this.projetGlobInfos.commercial = null

      this.$v.$reset()
    },
    passAuDetail() {
      this.$v.$reset()
      this.$v.projetGlobInfos.$touch()
      if (this.type === 'dirty') return
      if (this.$v.projetGlobInfos.$error) {
        return 0
      }

      this.isactive = 'importer_projet'

      $('#newnewproject')
          .modal('hide')
      this.isUpdate = false
    },
    updateprojet(value) {
      if (value.source_etude === 'Externe') {
        this.isUpdate = true
        this.updateEtude = value
        this.isactive = 'importer_projet'
      }
    },
    reloadprojet() {
      axios.get(`${url}api/projetlist/`)
          .then(r => {
            this.projetfill = r.data
          })
    },
    filteredRisque() {
      if (this.list_filter.Risque.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Risque.map(sel => sel)
            .includes(el.risque))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredAssureur() {
      if (this.list_filter.Assureur.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Assureur.map(sel => sel)
            .includes(el.assureur))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredAvancement() {
      if (this.list_filter.Avancement.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Avancement.map(sel => sel)
            .includes(el.avancement))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredStatut() {
      if (this.list_filter.Statut.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Statut.map(sel => sel)
            .includes(el.statut))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredCommercial() {
      if (this.list_filter.Commercial.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Commercial.map(sel => sel)
            .includes(el.commercial))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredType_de_client() {
      if (this.list_filter.Type_de_client.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Type_de_client.map(sel => sel)
            .includes(el.client_type))
        this.projetLocalFiltred = arrayResult
      }
    },
    filteredSource() {
      if (this.list_filter.Source.length > 0) {
        const arrayResult = this.projetLocalFiltred.filter(el => this.list_filter.Source.map(sel => sel)
            .includes(el.source))
        this.projetLocalFiltred = arrayResult
      }
    },
    methodesFilter() {
      //  this.projetLocalFiltred = [];
      this.projetLocalFiltred = this.projetLocal
      this.filteredRisque()
      this.filteredAssureur()
      this.filteredAvancement()
      this.filteredCommercial()
      this.filteredStatut()
      this.filteredType_de_client()
      this.filteredSource()
    },
    childToParent(value) {
      this.list_filter = value

      if (this.list_filter.Risque.length <= 0 && this.list_filter.Statut.length <= 0 && this.list_filter.Commercial.length <= 0 && this.list_filter.Assureur.length <= 0 && this.list_filter.Avancement.length <= 0 && this.list_filter.Type_de_client.length <= 0 && this.list_filter.Source.length <= 0) {
        this.statut_filtrage = false
        this.projetLocalFiltred = this.projetLocal
      } else {
        this.statut_filtrage = true
        this.methodesFilter()
      }
    },
    changeComponent(value) {
      this.isUpdate = false
      this.updateEtude = null
      this.isactive = value
    },
    reloadprojet_remove(id) {
      const index = _.findIndex(this.projetfill, { id })
      this.projetfill.splice(index, 1)
    },
    reloadprojet_add(array) {
      this.projetfill.unshift(array)
    },
    pushAndFusiondata() {
      this.projetLocal = []
      this.projetfill.forEach(item => {
        let client_nom = null
        let commercial_nom = null
        let commercial_array = null
        let categorie_nom = null
        let client_pporpm = null
        let client_id = null

        if (item.etude.etudeable_id !== null) {
          if (item.etude.etudeable !== null) {
            if (item.etude.etudeable_type === 'App\\Models\\PersonnePhysique') {
              client_nom = `${item.etude.etudeable.nom} ${item.etude.etudeable.prenom}`
            } else if (item.etude.etudeable_type === 'App\\Models\\PersonneMorale') {
              client_nom = item.etude.etudeable.denomination_commercial
            } else {
              client_nom = null
            }
          } else {
            client_nom = null
          }
        } else {
          client_nom = null
        }

        if (item.user != null) {
          commercial_nom = `${item.user.personne_physique.nom} ${item.user.personne_physique.prenom}`
          commercial_array = item.user.personne_physique
        } else {
          commercial_nom = null
          commercial_array = null
        }

        if (item.etude.etudeable_id !== null && item.etude.etudeable !== null) {
          categorie_nom = item.etude.etudeable.categorie
          client_pporpm = item.etude.etudeable.type
          client_id = item.etude.etudeable.id
        } else {
          categorie_nom = null
          client_pporpm = null
          client_id = null
        }

        if (item.etude.source == 'Interne') {
          let assureur_local = null
          if (item.tarification.length > 0) {
            assureur_local = item.tarification.find(i => i.preconise)

            if (assureur_local && assureur_local.produit) {
              // assureur_local = assureur_local.produit.assureur.nom_assureur ? assureur_local.produit.assureur.nom_assureur : ''
            } else {
              // assureur_local = item.assureur.nom_assureur ? item.assureur.nom_assureur : ''
            }
          }

          this.projetLocal.push({
            identifiant: this.$_.get(item, 'etude.etude_no', ''),
            id_etude: item.etude.id,
            courtier: item.courtier_id,
            source_etude: item.etude.source,
            id_projet: item.id,
            nom_projet: item.etude.source === 'Interne' ? item.devis_no_interne : item.devis_no_externe,
            nometude: item.etude.etude_nom,
            client: client_nom,
            client_type: categorie_nom,
            client_pporpm,
            client_id,
            risque: item.risque.label,
            assureur: assureur_local,
            avancement: item.devis_avancement,
            statut: item.statut,
            commercial: commercial_nom,
            commercial_array,
            date_effet: item.date_effet,
            date_creation: item.date_creation,
            source: item.etude.source,
            modifie_le: item.updated_at,
          })
        } else {
          this.projetLocal.push({
            identifiant: this.$_.get(item, 'etude.etude_no', ''),
            id_etude: item.etude.id,
            courtier: item.courtier_id,
            source_etude: item.etude.source,
            id_projet: item.id,
            nom_projet: item.etude.source === 'Interne' ? item.devis_no_interne : item.devis_no_externe,
            nometude: item.etude.etude_nom,
            client: client_nom,
            client_type: categorie_nom,
            client_pporpm,
            client_id,
            risque: item.risque.label,
            // assureur: item.assureur.nom_assureur ? item.assureur.nom_assureur : '',
            avancement: item.devis_avancement,
            statut: item.statut,
            commercial: commercial_nom,
            commercial_array,
            date_effet: item.date_effet,
            date_creation: item.date_creation,
            source: item.etude.source,
            modifie_le: item.updated_at,
          })
        }
      })
    },
    showProspect(prospectId, prospectCategorie, prospectType) {
      if (prospectCategorie.toLowerCase() === 'entreprise') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': prospectId,
            'type': prospectType,
            'interfaceChosen': 'Entreprise'
          }
        })
      } else if(prospectCategorie.toLowerCase() === 'professionnel' || prospectCategorie.toLowerCase() === 'particulier') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': prospectId,
            'type': prospectType,
            'interfaceChosen': 'Professionnel'
          }
        })
      }
      // if (prospectCategorie.toLowerCase() === 'entreprise' || prospectCategorie.toLowerCase() === 'professionnel') {
      //   const interfaceChosen = prospectCategorie.toLowerCase() === 'entreprise' ? 'Entreprise' : 'Professionnel'
      //   this.$router.push({
      //     name: 'tiers-list',
      //     params: {
      //       'id': prospectId,
      //       'type': prospect.type,
      //       'interfaceChosen': interfaceChosen
      //     }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'prospects-details',
      //     params: {
      //       'id': prospectId,
      //       'type': prospect.type,
      //       'prospect': prospect
      //     }
      //   })
      // }
    },
    showProjet(idProjet, typeProjet) {
      this.projetChosen.id = idProjet
      this.projetChosen.type = typeProjet
      this.interfaceChosen = 'FicheProjet'
    },
    recordDuplicateProjet(data, modalName = null) {
      this.showLoading = true
      this.$http.post(`devis/replicateDevis/${data.devisId}`, data).then(response => {
          if (response.data.success) {
            this.messageToast(response.data.message, 'Succès', 'success')
            if (response.data.data.etude_id !== null) {
              this.$router.push({
                name: 'projets-proposition',
                params: {
                  id: response.data.data.etude_id
                }
              })
            } else{
              this.clearAllFilter()
              this.getAllProjets(1)
            } 
            this.$bvModal.hide(modalName)
          }
        })
        .catch(err => {
          this.showLoading = false
          console.log(err)
        })
    },
    submitDuplicateProjet() {
      this.$refs.formRenewalRules.validate().then(success => {
        if (success) {
          let data = {
            devisId: this.selected_devisId,
            dateEffet: this.selected_date_effet,
            action: 'renewal'
          }
          this.recordDuplicateProjet(data, 'modal_renewal')
        }
      });
    },
    redirectprojet(value) {
      if (value.statut === 'Expire' ||
      ((value.risque_id == 74 || value.risque_id == 69 || value.risque_id == 13) &&
      new Date(value.date_effet) < new Date()) ) {
        this.$swal({
          text: 'Le devis est expiré, souhaitez-vous le dupliquer et le clore en sans suite ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: "Oui",
          cancelButtonText: "Non",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {

            this.showLoading = true
            let data = {
              devisId: value.id,
              dateEffet: null,
              action: 'duplicateWithArchive'
            }
            this.recordDuplicateProjet(data)
            this.showLoading = false
          }
        })
      } else {
        this.checkDateEffet(value)
      }
    },
    redirectExtranet(devis) {
      if(devis.espace_link) {
        window.open(devis.espace_link, '_blank');
      }
    },
    checkDateEffet(value) {
      let data = {
        devisId: value.id,
        checkOnlyChoixClient: value.statut == 'En attente signature' ? 1 : 0
      }
      this.$http.post(`devis/checkDateEffet`, data)
        .then(response => {
          if (response.data.expired) {
            // if(value.risque.label!=='Santé Séniors'){
            //   this.selected_devisId = value.id
            //   this.title_modal = response.data.titre
            //   this.msg_modal   = response.data.message
            //   this.$bvModal.show('modal_renewal')
            // }else{
                this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'Devis expiré',
                  icon: 'EditIcon',
                  variant: 'danger',
                  },
                })
              this.$swal({
              title: `${response.data.titre}`,
              text: `${response.data.message}`,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              this.showLoading = true
              if (result.value) {
                let data = {
                  devisId: value.id,
                  dateEffet: null,
                  action: 'duplicateWithArchive'
                }
                this.$http.post(`devis/replicateDevis/${value.id}`,data).then(response => {
                  if(response.data.success) {
                    this.messageToast(response.data.message, 'Succès', 'success')
                    this.getAllProjets(1)
                    this.$router.push({
                      name: 'projets-proposition',
                      params: {
                        id: response.data.data.etude_id
                      }
                    })
                  }
                })
                .catch(err => {
                  this.showLoading = false
                  console.log(err)
                })
              } else this.showLoading = false
            }) 
          } else {
            const etudeId = value.etude_id
            this.$store.dispatch('clearData')
            if (value.devis_avancement == 'TARIFICATION') {
              this.$router.push({
                name: 'projets-proposition',
                params: { id: etudeId }
              })
            }
            if (value.devis_avancement == 'SOUSCRIPTION') {
              this.$router.push({
                name: 'projets-souscription',
                params: { id: etudeId }
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    filterData() {
      let objet = {}
      if (this.filtre.dateEffet_entre_le) objet.dateEffet_entre_le = this.filtre.dateEffet_entre_le
      if (this.filtre.dateEffet_el_le) objet.dateEffet_el_le = this.filtre.dateEffet_el_le
      if (this.selectedAssureur) objet.selectedAssureur = this.selectedAssureur
      if (this.selectedRisque) objet.selectedRisque = this.selectedRisque
      if (this.selectedStatut) objet.selectedStatut = this.selectedStatut
      if (this.selectedCommercial) objet.selectedCommercial = this.selectedCommercial
      if (this.selectedTypeClient) objet.typeClient = this.selectedTypeClient

      if (this.recherche) objet.recherche = this.recherche

      return objet
    },
    // filterData() {
    //   this.projetfill = this.projet
    //   if (this.selectedAssureur) this.projetfill = this.projetfill.filter(item => item.assureur_id === this.selectedAssureur)
    //   if (this.selectedRisque) this.projetfill = this.projetfill.filter(item => item.risque_id === this.selectedRisque)
    //   if (this.selectedAvancement) this.projetfill = this.projetfill.filter(item => item.devis_avancement === this.selectedAvancement)
    //   if (this.selectedStatut) this.projetfill = this.projetfill.filter(item => item.statut === this.selectedStatut)
    //   if (this.selectedCommercial) this.projetfill = this.projetfill.filter(item =>item.user.personne_physique.prenom + ' ' + item.user.personne_physique.nom === this.selectedCommercial)
    //   if (this.selectedTypeClient) this.projetfill = this.projetfill.filter(item =>item.devisable.categorie === this.selectedTypeClient )
    //   if (this.filtre.dateEffet_entre_le) {
    //     this.projetfill = this.projetfill.filter(
    //       (item) =>
    //         new Date(
    //           moment(item.date_effet, "YYYY-MM-DD").format("YYYY-MM-DD")
    //         ) >=
    //         new Date(
    //           moment(
    //             this.filtre.dateEffet_entre_le,
    //             "DD/MM/YYYY"
    //           ).format("YYYY-MM-DD")
    //         )
    //     );
    //   }
    //   if (this.filtre.dateEffet_el_le) {
    //     this.projetfill = this.projetfill.filter(
    //       (item) =>
    //         new Date(
    //           moment(item.date_effet, "YYYY-MM-DD").format("YYYY-MM-DD")
    //         ) <=
    //         new Date(
    //           moment(this.filtre.dateEffet_el_le, "DD/MM/YYYY").format(
    //             "YYYY-MM-DD"
    //           )
    //         )
    //     );
    //   }
    //   this.json_data = this.projetfill
    // },
    clearAllFilter() {
      this.selectedRisque = null
      this.selectedAssureur = null
      this.selectedAvancement = null
      this.selectedStatut = null
      this.searchTerm = null
      this.recherche = null
      this.selectedCommercial = null
      this.selectedTypeClient = null
      this.filtre.dateEffet_entre_le = null
      this.filtre.dateEffet_el_le = null
      this.getAllProjets(1)
    },
    renvoyerSignature(){
      this.disableRenvoyer = true
      this.$http.post("portefeuilles/renvoyerSignature",this.renvoyerDataForm).then((res)=>{
        console.log(res.data);
        this.messageToast(res.data.message, 'Succès', 'success')
        this.$refs['renvoyer_signature'].hide()
        this.backToListing('Listing')
        this.clearAllFilter()
        this.getAllProjets()


      }).catch((err)=>{
        console.log(err);
        this.messageToast(err.response.data.message, 'Erreur', "danger")

      }).finally(()=>{
        this.disableRenvoyer = false
      })
      
    },
    resetRenvoyerSignatureModal(){
      this.renvoyerDataForm.email = "";
      this.renvoyerDataForm.indicatif = "";
      this.renvoyerDataForm.telephone = "";
      this.renvoyerDataForm.idDevis = "";
      this.renvoyerDataForm.idMoyContact = "";    
      this.renvoyerDataForm.numDevis = "";    
      this.renvoyerDataForm.nomClient = "";  
      this.renvoyerDataForm.prenomClient = "";  
      this.renvoyerDataForm.lien_signature = "resend";  
      this.disableRenvoyer = false
    },
    fillRenvoyerSignatureModalData(row){
      console.log(row)
      this.renvoyerDataForm.email = row.devisable.moyen_contact.email;
      this.renvoyerDataForm.indicatif = row.devisable.moyen_contact.indicatif_tel;
      this.renvoyerDataForm.telephone = row.devisable.moyen_contact.tel;
      this.renvoyerDataForm.idDevis = row.id;
      this.renvoyerDataForm.idMoyContact = row.devisable.moyen_contact.id;    
      this.renvoyerDataForm.numDevis = row.devis_no_interne;    
      this.renvoyerDataForm.nomClient = row.devisable.nom;    
      this.renvoyerDataForm.prenomClient = row.devisable.prenom;    
      this.renvoyerDataForm.lien_signature = "resend";  

      console.log(this.renvoyerDataForm);
      
    },
    checkDateEnvoiSignValide(date_envoi_signature){
      
      const signatureDate = moment(date_envoi_signature);
      const expirationDate = signatureDate.add(14, "days");
      const now = moment();
      
      return now.isBefore(expirationDate);
    },
    sendSignatureLinkBySms(){
      this.disableRenvoyer = true
      this.$http.post("portefeuilles/sendSignatureLinkBySms",this.renvoyerDataForm).then((res)=>{
        console.log(res.data);
        this.messageToast(res.data.message, 'Succès', 'success')
        this.$refs['renvoyer_signature_sms'].hide()


      }).catch((err)=>{
        console.log(err);
        this.messageToast(err.response.data.message, 'Erreur', "danger")

      }).finally(()=>{
        this.disableRenvoyer = false
      })
    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';


input#indicatif.vs__search{
  padding: 0px;
}
.indicatif-chooser .vs__selected-options{
  height: 31px;
}
.indicatif-chooser .vs__dropdown-toggle {
  border-radius: 0.357rem 0px 0px 0.357rem;
  padding-bottom: 4.5px;
  width:111%;
}
.v-select .indicatif-chooser .vs--open .vs--single .vs--searchable
{
  min-width: 30%;
  width:30%;
}
</style>
