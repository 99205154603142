<template>
  <div>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-1 mt-1" v-if="interfaceContrat === 'Listing'">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1">Contrats ({{ rows.length }})</h1>
        </div>
        <div class="d-flex flex-row justify-content-end flex-wrap" >
          <div class="mb-1 mr-1" v-if="interfaceContrat === 'Listing'">
            <import-base-client-contrat  @fetch-contrats-list="fetchContratsList"   />
          </div>
          <div v-if="can('Import contract') || can('Update contract')" class="mr-1 mb-1">
            <actions-contrat  :assureurs-options="assureursOptions" :clients-options="clientsOptions" :interface-chosen="interfaceContrat"
                            :commercial-options="commercialOptions" :current-user="currentUser"
                            :data-selected-for-edit="dataSelectedForEdit" :is-update.sync="isUpdate"
                            :risques-options="risquesOptions" @is-update-function="isUpdateFunction"
                            @record-contrat-returned="recordContratReturned"
                            @record-document-returned="recordDocumentReturned"
                            :client-id="joindre_client" :contrat-id="joindre_contrat"
                            :client-type="client_type_id" :is-portfeuille="isPortfeuille"
            />
        </div>
      </div>



      </div>
      <div v-if="interfaceContrat === 'Listing'">
        <b-card v-if="can('List contracts of broker') || can('List contracts by commercial')" title="FILTRES">
          <div class="custom-search">
              <!-- advance search input -->
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Période comprise entre le"
                    label-for="entrele"
                  >
                    <flat-pickr
                      id="entrele"
                      v-model="filtre.dateEffet_entre_le"
                      :config="configDate"
                      class="form-control"
                      placeholder="Date d'effet"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Et le" label-for="et_le">
                    <flat-pickr
                      id="et_le"
                      v-model="filtre.dateEffet_el_le"
                      :config="configDate"
                      class="form-control"
                      placeholder="Date d'effet"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Gestionnaire" label-for="commercial">
                    <b-form-select id="commercial" v-model="selectedCommercial" :options="commercialOptions"
                                  text-field="nom" value-field="nom"
                    >
                      <template #first>
                        <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Type client" label-for="type-client">
                    <b-form-select id="type-client" v-model="selectedTypeClient" :options="optionsTypeClient"/>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Assureur" label-for="assureur">
                    <b-form-select id="assureur" v-model="selectedAssureur" :options="assureursOptions"/>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Risque" label-for="risque">
                    <b-form-select id="risque" v-model="selectedRisque" :options="risquesOptions"/>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Commission" label-for="commission">
                    <b-form-select id="commission" v-model="selectedCommision" :options="commissionOptions"/>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Statut" label-for="statut">
                    <b-form-select id="statut" v-model="selectedStatut" :options="statusOptions"/>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Recherche">
                    <b-input-group>
                      <b-form-input
                          v-model="recherche"
                          :lazy="true"
                          id="basic-recherche"
                          class="d-inline-block"
                          type="text"
                          placeholder="Recherche"
                          @keyup.enter="searchInput"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            icon="SearchIcon"
                            class="cursor-pointer"
                            @click="searchInput"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="1" class="mt-1">
                  <b-button v-b-tooltip.hover.top="'Tout afficher'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="btn-icon mt-1"
                            variant="primary"
                            @click="clearAllFilter"
                  >
                    <feather-icon icon="RefreshCwIcon"/>
                  </b-button>
                </b-col>
              </b-row>
          </div>
        </b-card>
        <b-card v-if="can('List contracts of broker') || can('List contracts by commercial')"

        >
          <b-overlay :show="showLoading" no-wrap/>
          <export-excel
          class   = "btn btn-primary mb-1"
          :data   = "json_data"
          :fields = "json_fields"
          worksheet = "les contrats"
          v-if="can('Export Contracts') || currentUser.courtier_user[0].isPrincipal"
          name    = "contrats.xls">
              <span>Exporter </span>
              <feather-icon
                class="mr-50"
                icon="DownloadIcon"
              />
          </export-excel>
          <!-- table -->
          <vue-good-table
              :columns="columns"
              :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
              @on-selected-rows-change="selectionChanged"
              :rows="rows"
              :rtl="direction"
              :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
              :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'police_no'" @click="showContrat(props.row.id, props.row.source)">
                <!-- {{ props.row.source === 'EXTERNE' ? props.row.police_no : props.row.contrat_no }} -->
                <!-- https://bubble-in.atlassian.net/browse/BUB-875 -->
                <!-- <div v-if="props.column.field == 'projetNum'"> -->
                  <a style="text-decoration: underline;">{{ props.row.police_no }} </a>
                <!-- </div> -->
              </span>
              <span v-else-if="props.column.field === 'source'">
                <b-badge :variant="sourceVariant(props.row.source)">
                  {{ capitalizeFirstLetter(props.row.source) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'statut'">
                  {{ changeText(props.row.statut) }}
              </span>
              <span v-else-if="props.column.field == 'contrable_nom'" @click="showProspect(true, props.row)">
                <a style="text-decoration: underline;">{{ props.row.contrable_nom }}</a>
              </span>

              <span class="d-flex justify-content-evenly" v-else-if="props.column.field == 'commission_recurrente'" >
                <span class="w-100  d-flex align-items-center justify-content-center bd-highlight">
        {{ props.row.commission_recurrente }}
                  </span> 
                <span class="text-info w-50 d-flex align-items-start justify-content-center" v-if="props.row.previsionnel == '1'" style="background:#C5FAF7;border-radius: 180px;padding:6px; font-size: 15px;" >P</span>
              </span>
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                    <template v-slot:button-content>
                      <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                    </template>
                    <b-dropdown-item  @click="showContrat(props.row.id, props.row.source)">
                      <feather-icon class="mr-50" icon="Edit2Icon"/>
                      <span>Consulter la fiche contrat</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="can('Update contract') && props.row.source === 'EXTERNE'"
                                    v-b-toggle.sidebar-contrat
                                        @click="editContrat(props.row)"
                    >
                      <feather-icon class="mr-50" icon="Edit2Icon"/>
                      <span>Modifier</span>
                    </b-dropdown-item>
                        <b-dropdown-item
                        v-if="props.row.devis_avancement == 'TARIFICATION' && props.row.etude.source === 'INTERNE'"
                        @click="redirectprojet(props.row)"
                    >
                      <feather-icon
                          class="mr-50"
                          icon="EditIcon"
                      />
                      <span>Finaliser</span>
                    </b-dropdown-item>
                     <b-dropdown-item v-b-modal.modal_1 @click="attachCommercial(props.row)">
                      <feather-icon
                          class="mr-50"
                          icon="Link2Icon"
                      />
                      <span>Attacher à un gestionnaire</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-b-toggle.sidebar-importNewContrat @click="joindreFile(props.row.contrable_id,props.row.id,props.row.contrable_type)">
                      <feather-icon class="mr-50" icon="PaperclipIcon"/>
                      <span>Joindre</span>
                    </b-dropdown-item>
                    <b-dropdown-item  @click="JoindreContrat(props.row.id)">
                      <feather-icon class="mr-50" icon="SearchIcon"/>
                      <span>Visualiser</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="can('Delete contract') && props.row.source === 'EXTERNE'" @click="deleteContrat(props.row.id, props.row.vgt_id)">
                      <feather-icon class="mr-50" icon="TrashIcon"/>
                      <span>Supprimer</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="can('Delete contract') && props.row.source === 'INTERNE' && props.row.statut !== 'ANNULER'" @click="annulerContrat(props.row.id)">
                      <feather-icon class="mr-50" icon="XCircleIcon"/>
                      <span>Annuler</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                  <b-col  md="6" xl="4">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap ">
                        Affichage 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap"> de {{ props.total }} entrées </span>
                    </div>
                  </b-col>
              </b-row>
              <b-row class="mt-2 align-items-center">
                <b-col md="4">
                  <b-input-group>
                    <b-form-select v-model="selected_action" :options="options"/>
                    <b-input-group-append>
                      <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="action_masse(selected_action)"
                        >
                          <span>Valider </span>
                          <feather-icon
                              class="mr-50"
                              icon="ArrowRightIcon"
                          />
                        </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col md="8">
                  <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                                last-number next-class="next-item" prev-class="prev-item"
                                @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </template>
            <div slot="emptystate" class="text-center align-middle">
              <span>Aucune donn&eacute;e disponible dans le tableau</span>
            </div>
          </vue-good-table>
        </b-card>
        <b-modal centered size="lg" id="modal-display-document" ref="modal-display-document" :hide-footer="hideFooter" >
                <autres-document :courtier-id="joindre_courtier" :contrat-id-doc="joindre_contrat_doc"/>
        </b-modal>

        <b-modal
          id="modal_1"
          hide-footer
          centered
          title="Attacher à un gestionnaire"
        >
        <b-form
              class="p-2"
              @submit.prevent="commercialClientSave"
            >
          <b-form-group label="Gestionnaire" label-for="commercial">
             <b-form-select
                    v-model="attached_commercial"
                    id="commercial"
                  >
              <b-form-select-option :value="null"> -- Choisissez --</b-form-select-option>
              <b-form-select-option v-for="(commercial, index) in commercialOptions" :key="index" :value="commercial.id">{{ commercial.nom }}</b-form-select-option>
             </b-form-select>
          </b-form-group>
          <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="attached_commercial == null || (clientsAttacher.length == 1 && clientsAttacher[0].actif_commercial == attached_commercial)"
                >
                  Attacher
                </b-button>
          </div>
        </b-form>
        </b-modal>
      </div>

    <div v-if="interfaceContrat === 'FicheContrat'">
      <parent-fiche-contrat
       @back-to-listing="backToListing"
       :contrat-chosen="contratChosen"
       :isContratUpdated="isContratUpdated"
       :isDocumentAdded="isDocumentAdded"
       @show-prospect="showProspectchild"
       @supprimer-contrat="deleteContrat"
       @update-contrat-externe="editContrat"
       @ajouter-document="joindreFile"
       @initial-docs-refreshed="recordDocumentReturned"
       @updated-iscontrat-data="updatedIscontratData"
      />

    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  VBToggle,
  VBTooltip,
  BModal,
  BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import flatPickr from "vue-flatpickr-component";

import Ripple from 'vue-ripple-directive'
import ActionsContrat from './ActionsContrat.vue'
import AutresDocument from '@/views/portefeuilles/contrats/AutresDocument'
import ImportBaseClientContrat from '@/views/portefeuilles/contrats/ImportBaseClientContrat'
import ParentFicheContrat from '@/views/contrats/ParentFicheContrat.vue'
import moment from 'moment'
export default {
  components: {
    ActionsContrat,
    VueGoodTable,
    flatPickr,
    BPagination,
    BFormGroup,
    BFormInput,
    BBadge,
    BForm,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormSelectOption,
    BModal,
    BImg,
    AutresDocument,
    ImportBaseClientContrat,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ParentFicheContrat
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      attached_commercial: null,
      clientsAttacher: [],
      json_fields: {
        'N° contrat': 'police_no',
        'Risque': 'risque',
        'Client': 'contrable_nom',
        'Assureur': 'assureur',
        'Statut': {
          field: 'statut',
              callback: (value) => {
                if(value == 'EN_COURS'){
                  return `En cours`;
                }
                if(value == 'SUSPENDU')
                {
                  return `Suspendu`;
                }
                if(value == 'REDUIT')
                {
                  return `Réduit`;
                }
                if(value == 'MISE_EN_DEMEURE')
                {
                  return `Mise en demeure`;
                }
                if(value == 'RESILIE')
                {
                  return `Résilié`;
                }
                if(value == 'SANS_EFFET')
                {
                  return `Sans effet`;
                }
                if(value == 'SUSPENDU')
                {
                  return `Suspendu`;
                }
                return null;
              }
            },
        'Source': 'source',
        'Date d\'effet': 'date_effet',
        'Prime TTC': 'prime',
        'Commission': 'commission_recurrente',
        'Prévisionnel': {
          field: 'previsionnel',
          callback: (value) => {
            if (value == 1) {
              return `Oui`;
            }
            return `Non`;
          }
        },
      'Gestionnaire': 'commercial_nom',
          'Date de création': 'date_creation',
      },
      json_data: [],
      json_meta: [
        [
          {
            'key': 'charset',
            'value': 'utf-8'
          }
        ]
      ],
      configDate: {
        dateFormat: "d/m/Y",
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement;
            var val = ele.value;

            if (
              (val.length == 2 || val.length == 5) &&
              ele.getAttribute("id")
            ) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      },
      recherche: null,
      pageLength: 10,
      hideFooter:false,
      isPortfeuille: true,
      joindre_client:null,
      joindre_contrat:null,
      client_type_id:null,
      joindre_contrat_doc:null,
      joindre_courtier:null ,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      dir: false,
      isUpdate: false,

      options: [
        {
          value: null,
          text: 'Choisissez'
        },
        {
          value: 'Supprimer',
          text: 'Supprimer'
        }
        // { value: 'Modifier', text: 'Modifier' },
        // { value: 'Archiver', text: 'Archiver' },
        // { value: 'Supprimer', text: 'Supprimer' }
      ],
      filtre: {
        dateEffet_el_le: null,
        dateEffet_entre_le: null,
      },
      optionsTypeClient: [
        {
          value: null,
          text: '-- Choisissez --'
        },
        {
          value: 'PARTICULIER',
          text: 'Particulier'
        },
        {
          value: 'PROFESSIONNEL',
          text: 'Professionnel'
        },
        {
          value: 'ENTREPRISE',
          text: 'Entreprise'
        }
      ],
      // filter
      selectedTypeClient: null,
      selectedAssureur: null,
      selectedRisque: null,
      selectedCommercial: null,
      selectedCommision: null,
      selectedStatut: null,

      selected_action: null,
      dataSelectedForEdit: {
        id: null,
        client: null,
        n_contrat: null,
        risque_id: null,
        assureur_id: null,
        date_effet: null,
        date_fin: null,
        echeance_anniversaire: "",
        conteur:0,
        typeFractionnement: null,
        statut: null,
        modalite_paiement: null,
        commercial: null,
        budget: null,
        taux_commission: null,
        courtier_id: null,
        concurrence: null,
        commission_unique_precompte: null,
        commission_recurrente: null,
        frais_courtage: null,
        previsionnel: 0,
        origine : null,
        createur : null,
        intermediaire: null,
        gestionnaire:null,
      },
      clientsOptions: [],
      commercialOptions: [],
      risquesOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      commissionOptions: [{
        value: null,
        text: '-- Choisissez --'
      },
      {
        value: true,
        text: 'Prévisionnelle'
      },
      {
        value: false,
        text: 'Non prévisionnelle'
      }],
      statusOptions: [{
        value: null,
        text: '-- Choisissez --'
      },
      {
        value: 'EN_COURS',
        text: 'En cours'
      },
      {
        value: 'MISE_EN_DEMEURE',
        text: 'Mise en demeure'
      },
      {
        value: 'REDUIT',
        text: 'Réduit'
      },
      {
        value: 'RESILIE',
        text: 'Résilié'
      },
      {
        value: 'SANS_EFFET',
        text: 'Sans effet'
      },
      {
        value: 'SUSPENDU',
        text: 'Suspendu'
      },
      {
        value: 'TEMPORAIRE',
        text: 'Temporaire'
      },
      {
        value: 'ANNULER',
        text: 'Annulé'  
      }
      ],
      assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      columns: [
        {
          label: 'N° police',
          field: 'police_no',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6',
          hidden: true
        },
        {
          label: 'N° contrat',
          field: 'police_no',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Risque',
          field: 'risque',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Client',
          field: 'contrable_nom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Assureur',
          field: 'assureur',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Source',
          field: 'source',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'date_effet',
          sortable: false,
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'échéance',
          field: 'echeance_anniversaire',
          sortable: false,
          type: 'date',
          dateInputFormat: 'dd/MM',
          dateOutputFormat: 'dd/MM',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Prime TTC',
          field: 'prime',
          sortable: false,
          type: 'decimal',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Commission',
          field: 'commission_recurrente',
          sortable: false,
          type: 'decimal',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Gestionnaire',
          field: 'commercial_nom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      source: [{
        1: 'EXTERNE',
        2: 'INTERNE',
      },
        {
          1: 'light-primary',
          2: 'light-warning'
        }],
      rows: [],
      rowsConst: [],
      contratAnnuler:[],
      searchTerm: '',
      showLoading: false,
      selectedrows_masse : [],
      interfaceContrat :'Listing',
      contratChosen: {
        id: null,
        type: null
      },
      isContratUpdated:false,
      isDocumentAdded:false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sourceVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        EXTERNE: 'light-primary',
        INTERNE: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    selectedTypeClient() {
      this.filterData()
    },
    
    selectedAssureur() {
      this.filterData()
    },
    selectedRisque() {
      this.filterData()
    },
    selectedCommercial() {
      this.filterData()
    },
    selectedCommision()
    {
      this.filterData()
    },
    selectedStatut()
    {
      this.filterData()
    },
    "filtre.dateEffet_entre_le": {
      handler(val) {
        this.filterData();
      },
    },
    "filtre.dateEffet_el_le": {
      handler(val) {
        this.filterData();
      },
    },
  },
  mounted() {
    this.hideFooter= true
    this.fetchRisqueList()
    this.fetchAssureurList()
    this.fetchClientList()
    this.fetchDataCommercialByCourtier()
    this.fetchContratsList()

    if (this.$route.params.id && this.$route.params.type && this.$route.params.interfaceContrat){
      this.contratChosen.id = this.$route.params.id
      this.contratChosen.type = this.$route.params.type
      this.interfaceContrat = 'FicheContrat'
    }
  },
  methods: {
    attachCommercial(data) {
      this.clientsAttacher.splice(0);
      this.attached_commercial = data.user_id
      this.clientsAttacher.push({
        actif_contrat: data.id,
        actif_commercial: data.user_id
      })
      console.log(this.clientsAttacher)

    },
    recordDocumentReturned(isAdded) {
      this.isDocumentAdded = isAdded
    },
    backToListing(value) {
      this.interfaceContrat = value
    },
    updatedIscontratData(value) {
      this.isContratUpdated = value
    },
    showContrat(idContrat, typeContrat) {
      this.contratChosen.id = idContrat
      this.contratChosen.type = typeContrat
      this.interfaceContrat = 'FicheContrat'
    },
    commercialClientSave() {

      this.$http.post("contrat/attachementContrat/", { 'contrat_id': this.clientsAttacher[0].actif_contrat, 'user_id': this.attached_commercial })
        .then((res) => {

          if (res.data.success) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Le client a été attaché avec un nouveau commercial`,
                  icon: "UserIcon",
                  variant: "success",
                },
              },
              {
                position: "top-right",
                timeout: 4000,
              }
            );
            this.$bvModal.hide('modal_1')
            this.fetchContratsList()
         
          }
        })
        .catch((err) => { });
    },
    action_masse(action){
      switch (action) {
        case 'Supprimer':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: "Êtes-vous sûr de vouloir supprimer les contrats ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.$http
                  .post("contrat/deletecontratmultiple/",{'contrats' : this.selectedrows_masse.selectedRows})
                  .then((res) => {
                    if (res.data.success) {
                      for(let i=0;i<this.rows.length;i++)
                      {
                        for(let j=0;j<res.data.deleted.length;j++)
                        {
                          if(this.rows[i].id == res.data.deleted[j].id)
                          {
                            this.rows.splice(i, 1)
                          }
                        }
                      }
                      this.json_data = this.rows
                      this.messageToast(res.data.success, 'Succès', 'success')
                    }
                  })
                  .catch((err) => {});

                this.$swal({
                  icon: "success",
                  title: "Supprimé!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
          }
          break;
          case 'Archiver':

          break;
        default:

      }
    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
    },
       redirectprojet(value) {
      const etudeId = value.etude_id
      this.$store.dispatch('clearData')
      if (value.devis_avancement == 'TARIFICATION') {
        this.$router.push({
          name: 'projets-proposition',
          params: { id: etudeId }
        })
      }
      if (value.devis_avancement == 'SOUSCRIPTION') {
        this.$router.push({
          name: 'projets-souscription',
          params: { id: etudeId }
        })
      }
    },
    joindreFile(client,contrat,clientType,fromChild=false){
    if(fromChild) 
    this.$root.$emit('bv::toggle::collapse', 'sidebar-importNewContrat')
    this.joindre_client = client
    this.joindre_contrat = contrat
    this.client_type_id =clientType
    },
    JoindreContrat(contrat){
        this.joindre_contrat_doc=contrat
        if (this.joindre_contrat_doc) {
          this.$refs['modal-display-document'].show()
       }
    },
    // Methode additional
    isUpdateFunction(boolValue) {
      this.isUpdate = boolValue
    },
    changeText(statut) {
      switch (statut) {
        case 'EN_COURS':
          return 'En cours'
          // eslint-disable-next-line no-unreachable
          break
        case 'SUSPENDU':
          return 'Suspendu'
          // eslint-disable-next-line no-unreachable
          break
        case 'REDUIT':
          return 'Réduit'
          // eslint-disable-next-line no-unreachable
          break
        case 'MISE_EN_DEMEURE':
          return 'Mise en demeure'
          // eslint-disable-next-line no-unreachable
          break
        case 'RESILIE':
          return 'Résilié'
          // eslint-disable-next-line no-unreachable
          break
        case 'SANS_EFFET':
          return 'Sans effet'
          // eslint-disable-next-line no-unreachable
          break
        case 'TEMPORAIRE':
          return 'Temporaire'
          // eslint-disable-next-line no-unreachable
          break
          case 'ANNULER':
          return 'Annulé'
          // eslint-disable-next-line no-unreachable
          break
        default:
          return null
          // eslint-disable-next-line no-unreachable
          break
      }
    },

    // fetch Data
    fetchRisqueList() {
      this.risquesOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/risques/fetchRisqueList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.risquesOptions.push({
                  value: el.id,
                  text: el.label
                })
              })
            }
          })
          .catch(err => {
            this.risquesOptions = [{
              value: null,
              text: '-- Choisissez --'
            }]
            console.error(err)
          })
    },
    fetchAssureurList() {
      this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur
                })
              })
            }
          })
          .catch(err => {
            this.assureursOptions = [{
              value: null,
              text: '-- Choisissez --'
            }]
            console.error(err)
          })
    },
    fetchClientList() {
      this.clientsOptions = []
      this.$http
          .post('/prospect/fetchClientList', { courtier_user_id: this.currentUser.courtier_user[0].id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.clientsOptions.push({
                  id: el.id,
                  type: el.type,
                  nom: el.nom,
                  categorie: el.categorie,
                  statutFiche: el.statutFiche,
                  commercial: el.commercial_id
                })
              })
              
              this.clientsOptions = this.$_.orderBy(this.clientsOptions, [client => client.nom.toLowerCase()], ['asc'])
              
            }
          })
          .catch(err => {
            this.clientsOptions = []
            console.error(err)
          })
    },
    fetchDataCommercialByCourtier() {
      this.commercialOptions = []
      this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },

    // actions data
    editContrat(data,fromChild=false) {
      this.dataSelectedForEdit.conteur = 0
      if(fromChild) {
          this.$http.get(`/contrat/getContratDetailById/${data.id}`)
          .then(res => {
            console.log('inside')
            console.log(res.data.data)
            this.isContratUpdated = false
            this.transformContratData(res.data.data,fromChild)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        console.log('updatebyid')
        console.log(data)
        this.transformContratData(data,fromChild)
      }
    },
    transformContratData(data,fromChild){
              this.dataSelectedForEdit.id = data.id
      if(fromChild){
        this.$root.$emit('bv::toggle::collapse', 'sidebar-contrat')
        this.dataSelectedForEdit.client = {
          id: data.contrable.id,
          type: data.contrable.type,
          nom: data.contrable.nom,
          categorie: data.contrable.categorie,
          statutFiche: data.contrable.statutFiche
        }
        this.dataSelectedForEdit.date_effet = data.date_effet ? moment(data.date_effet, 'YYYY-MM-DD').format('DD/MM/YYYY') : null
        this.dataSelectedForEdit.date_fin   = data.date_fin ? moment(data.date_fin, 'YYYY-MM-DD').format('DD/MM/YYYY') : null

      }else{
        this.dataSelectedForEdit.client = {
          id: data.contrable_id,
          type: data.contrable_type,
          nom: data.contrable_nom,
          categorie: data.categorie,
          statutFiche: data.statutFiche
        }
          this.dataSelectedForEdit.date_effet = data.date_effet
          this.dataSelectedForEdit.date_fin = data.date_fin
      }
      this.dataSelectedForEdit.n_contrat = data.police_no
      this.dataSelectedForEdit.risque_id = data.risque_id
      this.dataSelectedForEdit.assureur_id = data.assureur_id
      this.dataSelectedForEdit.typeFractionnement = data.fractionnement
      this.dataSelectedForEdit.statut = data.statut
      this.dataSelectedForEdit.modalite_paiement = data.mode_paiement
      this.dataSelectedForEdit.commercial = {
        id: data.commercial_id,
        type: data.commercial_type,
        nom: data.commercial_nom
      }
      this.dataSelectedForEdit.budget = data.prime
      this.dataSelectedForEdit.taux_commission = data.taux
      this.dataSelectedForEdit.commission_unique_precompte = data.commission_unique_precompte
      this.dataSelectedForEdit.commission_recurrente = data.commission_recurrente
      this.dataSelectedForEdit.frais_courtage = data.frais_courtage
      this.dataSelectedForEdit.previsionnel = data.previsionnel
      if (data.origine != null) {
        if (data.origine == 'Co_courtage' || data.origine == 'Indicateur_affaires' || data.origine == 'Weedo_market' || data.origine == 'Recommandation' || data.origine == 'Réseaux_sociaux') {
          this.dataSelectedForEdit.origine = data.origine
        } else {
          this.dataSelectedForEdit.origine = 'Autre'
        }
      }
      this.dataSelectedForEdit.origineautre = data.origine

      if(data.createur_contrat === null){
          this.dataSelectedForEdit.createur = null
      }else{
          this.dataSelectedForEdit.createur = {
          id: data.createur_contrat.user.personne_physique.user_id,
          nom: `${data.createur_contrat.user.personne_physique.prenom} ${data.createur_contrat.user.personne_physique.nom} `,
          type: data.createur_contrat.user.personne_physique.type,
        };
      }
      console.log(data.user)

      if (data.user === null) {
        this.dataSelectedForEdit.gestionnaire = null
      } else {
        this.dataSelectedForEdit.gestionnaire = {
          id: data.user.personne_physique.user_id,
          nom: `${data.user.personne_physique.prenom} ${data.user.personne_physique.nom} `,
          type: data.user.personne_physique.type,
        };
      }

      this.dataSelectedForEdit.intermediaire = [];
      if(data.courtiers_users_by_intermediares === null)
      {
        this.dataSelectedForEdit.intermediaire = null
      }else {
        if (data.courtiers_users_by_intermediares && data.courtiers_users_by_intermediares.length > 0) {
          data.courtiers_users_by_intermediares.forEach(courtier => {
            this.dataSelectedForEdit.intermediaire.push({
              id: courtier.user.personne_physique.user_id,
              nom: `${courtier.user.personne_physique.prenom} ${courtier.user.personne_physique.nom}`,
              type: courtier.user.personne_physique.type
            });
          });
        } 
      }
       
      this.isUpdate = true

      this.dataSelectedForEdit.concurrence = data.concurrence

      this.dataSelectedForEdit.echeance_anniversaire = (() => {

        if (data.echeance_anniversaire) {
          console.log('44554123')
          if (/^\d{2}\/\d{2}$/.test(data.echeance_anniversaire)) {

            return data.echeance_anniversaire; 
          }
  
          return moment(data.echeance_anniversaire, 'DD/MM').format('DD/MM');
        }

        return ''; 
      })();
      
    },
    recordContratReturned(contrat, message,fromChild = false) {
      if (!this.isUpdate) {
        this.rows.push(contrat[0])
        this.rowsConst.push(contrat[0])
        this.messageToast(message, 'Succès', 'success')
        // this.fetchClientList()
      } else {
        const indexContrat = this.rows.findIndex(el => el.id === contrat[0].id)
        this.rows.splice(indexContrat, 1)
        this.rows.push(contrat[0])

        const indexContratConst = this.rowsConst.findIndex(el => el.id === contrat[0].id)
        this.rowsConst.splice(indexContratConst, 1)
        this.rowsConst.push(contrat[0])

        this.json_data = this.rows
        // // eslint-disable-next-line prefer-destructuring
        // this.rows[indexContrat] = contrat[0]
        // // eslint-disable-next-line prefer-destructuring
        // this.rowsConst[indexContrat] = contrat[0]
        this.messageToast(message, 'Succès', 'success')
        // this.fetchClientList()
        if(fromChild) {
          this.isContratUpdated = true
        }
      }
    },
    deleteContrat(contratId, index) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir supprimer ce contrat ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .delete(`/contrat/deleteContrat/${contratId}`)
                  .then(res => {
                    if (res.data.success) {
                      // this.rows.splice(index, 1)
                      // this.rowsConst.splice(index, 1)
                      this.fetchContratsList()
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.interfaceContrat = 'Listing'
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
            }
          })
    },
    annulerContrat(contratId, index) {
      this.$swal({
        title: '',
        text: 'êtes vous certain de vouloir annuler ce contrat sur BUBBLE-IN ? Vous devrez faire les démarches auprès de la compagnie pour que l’annulation soit effective',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .put(`/contrat/annulerContrat/${contratId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.fetchContratsList()
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.interfaceContrat = 'Listing'
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
            }
          })
    },

    // fetch Data
    fetchContratsList() {
      this.showLoading = true
      this.$http
          .post('/contrat/fetchDataContrats', {
            courtier_user_id: this.currentUser.courtier_user[0].id,
            user_id: this.currentUser.courtier_user[0].user_id,
            courtier_id: this.currentUser.courtier_user[0].courtier_id
          })
          .then(res => {
            if (res.data.success) {
              this.rows = res.data.data.filter(item => item.statut !== 'ANNULER')
              this.rowsConst = res.data.data.filter(item => item.statut !== 'ANNULER')
              this.contratAnnuler = res.data.data.filter(item => item.statut === 'ANNULER')
              this.json_data = this.rows
              this.showLoading = false
            }
          })
          .catch(err => {
            this.showLoading = false
            console.error(err)
          })
    },
    showProspect(is_projet, prospect) {
      if (prospect.contrable_categorie.toLowerCase() === 'entreprise') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': prospect.contrable_id,
            'type': prospect.contrable_type,
            'interfaceChosen': 'Entreprise'
          }
        })
      } else if(prospect.contrable_categorie.toLowerCase() === 'professionnel' || prospect.contrable_categorie.toLowerCase() === 'particulier') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': prospect.contrable_id,
            'type': prospect.contrable_type,
            'interfaceChosen': 'Professionnel'
          }
        })
      }
    },
    showProspectchild(id,categorie, type) {
      if (categorie.toLowerCase() === 'entreprise') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': id,
            'type': type,
            'interfaceChosen': 'Entreprise'
          }
        })
      } else if(categorie.toLowerCase() === 'professionnel' || categorie.toLowerCase() === 'particulier') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': id,
            'type': type,
            'interfaceChosen': 'Professionnel'
          }
        })
      }
    },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.recherche != null) {
          this.searchTerm = this.recherche
          this.showLoading = false
        }
        this.showLoading = false
      }, 500);
    },
    clearAllFilter() {
      this.selectedTypeClient = null
      this.selectedAssureur = null
      this.selectedRisque = null
      this.selectedCommercial = null
      this.selectedCommision = null
      this.selectedStatut = null
      this.rows = this.rowsConst
      this.searchTerm = ''
      this.filtre.dateEffet_entre_le = null
      this.filtre.dateEffet_el_le = null
      this.recherche = ''

    },
    filterData() {
      this.rows = this.rowsConst
      if (this.selectedTypeClient) this.rows = this.rows.filter(item => item.contrable_categorie === this.selectedTypeClient)
      if (this.selectedAssureur) this.rows = this.rows.filter(item => item.assureur_id === this.selectedAssureur)
      if (this.selectedRisque) this.rows = this.rows.filter(item => item.risque_id === this.selectedRisque)
      if (this.selectedCommercial) this.rows = this.rows.filter(item => item.commercial_nom === this.selectedCommercial)
      if (this.selectedStatut) {
        if (this.selectedStatut === 'ANNULER') {
          this.rows = this.contratAnnuler;
        } else {
          this.rows = this.rows.filter(item => item.statut === this.selectedStatut);
        }
      }
      if (this.selectedCommision != null) this.rows = this.rows.filter(item => item.previsionnel == this.selectedCommision)
      if (this.filtre.dateEffet_entre_le) {
        this.rows = this.rows.filter(
          (item) =>
            new Date(
              moment(item.date_effet, "DD/MM/YYYY").format("YYYY-MM-DD")
            ) >=
            new Date(
              moment(
                this.filtre.dateEffet_entre_le,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD")
            )
        );
      }
      if (this.filtre.dateEffet_el_le) {
        this.rows = this.rows.filter(
          (item) =>
            new Date(
              moment(item.date_effet, "DD/MM/YYYY").format("YYYY-MM-DD")
            ) <=
            new Date(
              moment(this.filtre.dateEffet_el_le, "DD/MM/YYYY").format(
                "YYYY-MM-DD"
              )
            )
        );
      }
      console.log(this.rows)
      if (!this.filtre.dateEffet_entre_le && this.filtre.dateEffet_entre_le != null && !this.filtre.dateEffet_el_le && this.filtre.dateEffet_el_le != null && !this.selectedTypeClient && !this.selectedAssureur && !this.selectedRisque && !this.selectedCommercial && this.selectedCommision == null && this.selectedStatut == null) this.rows = this.rowsConst
    
      this.json_data = this.rows
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
.flatpickr-current-month .flatpickr-monthDropdown-months span, .flatpickr-current-month .numInputWrapper span
{
  display: block !important;
}
</style>
