<template>
    <div>
        <div class="d-flex justify-content-between flex-wrap">
         
          <div class="d-flex flex-row justify-content-end flex-wrap"  v-if="interfaceReclamation === 'Listing'" >
            <div  class="mr-1 mb-1">
              <actions-reclamations :interface-chosen="interfaceReclamation"
                @is-update-function="isUpdateFunction"
                @record-reclamation-returned="recordReclamationReturned"
                @fetch-data-ref="fetchDataRef"
                :is-update.sync="isUpdate"
                :reclamation-for-edit="reclamationForEdite"
                :prospect-options="prospectData"
                :assureurs-options="assureursOptions"
                :projet-contrat-data="projetContratData"
                :commercial-options="commercialOptions"
                :is-fiche-client="isFicheClient"
                :tiers-data="tiersData"
              />
          </div>
        </div>

        </div>
        <div v-if="interfaceReclamation === 'Listing'">
          <b-card>
            <div class="custom-search">
                <!-- advance search input -->
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Période comprise entre le" label-for="entrele">
                     
                          <flat-pickr id="entrele" v-model="filtre.date_reclamation_entre_le" :config="configDate" class="form-control" placeholder="Date de réclamation" />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Et le" label-for="et_le">
                      <flat-pickr id="et_le" v-model="filtre.date_reclamation_el_le" :config="configDate" class="form-control" placeholder="Date de réclamation" />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Motif" label-for="Motif">
                      <b-form-select id="Motif" v-model="selectedMotif" :options="MotifOptions"/>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Assureur" label-for="assureur">
                      <b-form-select id="assureur" v-model="selectedAssureur" :options="assureur_options_list"/>
                    </b-form-group>
                  </b-col>
                 
                  <b-col md="2">
                    <b-form-group label="Issue" label-for="Issue">
                      <b-form-select id="Issue" v-model="selectedIssue" :options="issueOptions"/>
                    </b-form-group>
                  </b-col>
                  
                <b-col md="3">
                  <b-form-group  label="Statut" label-for="statut">
                    <b-form-select id="statut"  v-model="selectedStatus" :options="StatusOptions" />
                  </b-form-group>
                </b-col>
  
                  <b-col md="3">
                    <b-form-group label="Recherche" label-for="basic-recherche">
                      <b-input-group>
                        <b-form-input
                            v-model="recherche"
                            :lazy="true"
                            id="basic-recherche"
                            class="d-inline-block"
                            type="text"
                            placeholder="Recherche"
                            @keyup.enter="searchInput"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                              icon="SearchIcon"
                              class="cursor-pointer"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="1" class="mt-1">
                    <b-button v-b-tooltip.hover.top="'Tout afficher'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="btn-icon mt-1"
                              variant="primary"
                              @click="clearAllFilter"
                    >
                      <feather-icon icon="RefreshCwIcon"/>
                    </b-button>
                  </b-col>
                </b-row>
            </div>
          </b-card>
          <b-card
  
          >
            <b-overlay :show="showLoading" no-wrap/>
            
        <export-excel
          class   = "btn btn-primary mb-1"
          :data   = "json_data"
          :fields = "json_fields"
          worksheet = "reclamation"
          name    = "liste_client_reclamant.xls">
              <span>Exporter </span>
              <feather-icon
                class="mr-50"
                icon="DownloadIcon"
              />
          </export-excel>
            <!-- table -->
            <vue-good-table
                :columns="columns"
                :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
                @on-selected-rows-change="selectionChanged"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                enabled: true,
                externalQuery: searchTerm
              }"
                :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'lignes sélectionnées',
                clearSelectionText: 'Effacer la sélection',
                disableSelectInfo: false,
                selectAllByGroup: false
              }"
            >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'num_reclamation'" @click="showReclamation(props.row)">
           
                  <a style="text-decoration: underline;">{{ props.row.num_reclamation }} </a>
              </span>
         
              <span v-else-if="props.column.field == 'client' && props.row.reclamation_client != null"  @click="showProspect(props.row.reclamation_client.id, props.row.reclamation_client.type)" >
                <a style="text-decoration: underline;">{{ props.row.client }}</a>
              </span>

              <span v-else-if="props.column.field === 'issue'">
                  {{ changeText(props.row.issue) }}
              </span>
              
              <span v-else-if="props.column.field === 'motif'">
                  {{ changeTextmotif(props.row.motif) }}
              </span>

             
              <span v-else-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">

                    <template v-slot:button-content>
                      <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                    </template>

                    <b-dropdown-item v-if="can('Modify complaint')"
                    v-b-toggle.sidebar-reclamation
                    @click="editReclamation(props.row)"
                    >
                      <feather-icon class="mr-50" icon="Edit2Icon"/>
                      <span>Modifier</span>
                    </b-dropdown-item>
                    
                    <b-dropdown-item  v-if="props.row.etat != 'HISTORISER'" @click="hisotorie(props.row.id)">
                      <feather-icon class="mr-50" icon="PlusIcon"/>
                      <span>Historiser</span>
                    </b-dropdown-item>
                    
                  <b-dropdown-item 
                    @click="joindreFile(props.row)"
                  >
                    <feather-icon class="mr-50" icon="PaperclipIcon" />
                    <span>Joindre</span>
                  </b-dropdown-item>
                    
                  </b-dropdown>
                </span>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
  
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <b-row class="mt-2 align-items-center">
                    <b-col md="6" lg="5" xxl="3">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Affichage 1 à
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> de {{ props.total }} entrées </span>
                      </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2 align-items-center">
                  <b-col md="4">
                    <b-input-group>
                      <b-form-select v-model="selected_action" :options="options" />
                      <b-input-group-append>
                        <b-button @click="action_masse(selected_action)"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                          >
                            <span>Valider </span>
                            <feather-icon
                                class="mr-50"
                                icon="ArrowRightIcon"
                            />
                          </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                  <b-col md="8">
                    <b-pagination :per-page="pageLength" :total-rows="props.total" :value="1" align="right" first-number
                                  last-number next-class="next-item" prev-class="prev-item"
                                  @input="value => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card>

          <ActionsDocument  v-if="interfaceReclamation === 'Listing'" @fetch-document="$emit('refersh-document')" :reclamation = "reclamationlist"  />

      
        </div>
  
    
    </div>
  </template>
  
  <script>
  import {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BPagination,
    BRow,
    VBToggle,
    VBTooltip,
    BModal,
    BImg,
  } from 'bootstrap-vue'
  import flatPickr from 'vue-flatpickr-component'

  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  import Ripple from 'vue-ripple-directive'
  import ActionsDocument from "@/views/portefeuilles/reclamations/ActionDocument.vue"
  import ReclamationOptions from "@/shared/constants/reclamation"
  import ActionsReclamations from '../../../../portefeuilles/reclamations/ActionsReclamations'
//   import ParentFicheReclamation from './detail_fiche_reclamation/ParentFicheReclamation.vue'
  import moment from 'moment'
  export default {
    components: {
      flatPickr,
      ActionsReclamations,
      VueGoodTable,
      BPagination,
      BFormGroup,
      BFormInput,
      BBadge,
      BForm,
      ActionsDocument,
      BFormSelect,
      BInputGroup,
      BInputGroupAppend,
      BRow,
      BCol,
      BButton,
      BCard,
      BDropdown,
      BDropdownItem,
      BOverlay,
      BFormSelectOption,
      BModal,
      BImg,
      ToastificationContent,
      moment
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data() {
      return {
        reclamationForEdite: {
          id: null,
          is_client: false,
          reclamant: {
            id: null,
            idClient: null,
            type: null,
            categorie: null,
            client: null,
            nom: null,
            prenom: null
          },
          num_reclamation: null,
          date_reclamation: null,
          canal_reclamtion: null,
          date_acc_recep: null,
          declarant: null,
          statut: null,
          assureur_id: null,
          motif: null,
          service: null,
          autre_service: null,
          user: null,
          courtier_id: null,
          niveau: null,
          reference: {
            id: null,
            numero: null,
            source: null,
            type: null,
            ref_externe: null,
          },
          comment: null,
          issue: null,
          date_cloture: null,
          reponse_app: null,
          mesure_cor: null
        },
        isReclamationUpdated: false,
        commercialOptions: [],
        prospectChosen : {
          id: null,
          type: null
        },
        projetContratData: [],
        prospectData: [],
        assureursOptions: [{
          value: null,
          text: '-- Choisissez --'
        }],
       json_fields: {
          "N° réclamation": "num_reclamation",
          "Réclamant": "client",
          Motif: {
            field: "motif",
            callback: (value) => {
              return this.changeTextmotif(value);
            }
          },
          Assureur: "assureur",
          "Date de réclamation": "date_reclamation",
          Issue: {
            field: "issue",
            callback: (value) => {
              return this.changeText(value);
            }
          },
          "Date de clôture": "date_cloture",
          Statut: {
            field: "statut",
            callback: (value) => {
              return this.changeTextStatus(value);
            }
          },
          "Canal de réclamation": "canal_reclamtion",
          Declarant: "declarant",
          "Date d'accusé de réception": "date_acc_recep",
          "Niveau de traitement": "niveau",
          "Service concerné": "service_concerne",
          "Réponse apporté": {
            field: "reponse_app",
            callback: (value) => {
              return this.stripHtml(value);
            }

          },
          "Mesures correctives": {
            field: "mesure_cor",
            callback: (value) => {
              return this.stripHtml(value);
            }

          },
          "Référence": "reference_type",
          "Date de création": "created_at",
          "Commentaire": {
            field: 'comment',
            callback: (value) => {
              return this.stripHtml(value);
            }
          },

        },
        json_data: [],
        isUpdate: false,
        selectedrows_masse : [],
        recherche: null,
        pageLength: 10,
        selectedIssue:null,
        selected_action: null,
        reclamationlist:null,
        selectedMotif:null,
        selectedStatus: 'EN_COURS',
        issueOptions : ReclamationOptions['ISSUES'],
        MotifOptions: ReclamationOptions['MOTIFS'],
        StatussOptions: ReclamationOptions["STATUS"],

        StatusOptions: [
          {
            value: 'EN_COURS',
            text: 'En cours'
          },
          {
            value: 'HISTORISER',
            text: 'Historiser'
          },
        ],


        filtre :{
          date_reclamation_entre_le: null,
          date_reclamation_el_le: null,


        },
        currentUser: JSON.parse(localStorage.getItem('userData')),
        options: [
          {
            value: null,
            text: 'Choisissez'
          },
          {
            value: 'Historiser',
            text: 'Historiser'
          }
        ],  
        columns: [
          {
            label: 'N° réclamation',
            field: 'num_reclamation',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6',
          },
          {
            label: 'Motif',
            field: 'motif',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          },
          {
            label: 'Assureur',
            field: 'assureur',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          },
          {
            label: 'Date de réclamation',
            field: 'date_reclamation',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          },
          {
            label: 'Issue',
            field: 'issue',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          },
          {
            label: 'Date de clôture',
            field: 'date_cloture',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          },
          {
            label: 'Actions',
            field: 'actions',
            thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
            tdClass: 'text-center m-0 p-1 align-middle h6'
          }
        ],
        configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
            if (event.keyCode != 8) {
              var ele = document.activeElement
              var val = ele.value

              if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
                val += '/'
              }
              ele.value = val
              ele.dispatchEvent(new Event('input'))
            }
          }
        },
        showLoading: false,
        rows: [],
        rowsConst: [],
        searchTerm: '',
        showLoading: false,
        selectedrows_masse : [],
        interfaceReclamation :'Listing',
        reclamationChosen: {
          id: null,
          type: null
        },
        selectedAssureur: null,
        assureur_list: [{
          value: null,
          text: '-- Choisissez --'
        }],
        assureur_options_list:[{
          value: null,
          text: '-- Choisissez --'
      }]

      }
    },
    computed: {
    direction() {
        if (store.state.appConfig.isRTL) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = true
            return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
      return this.dir
    },
        
   
    },
    props: {
        prospectChosene: {
            type: Object,
            default: null,
            required: true,
            
        },
        isFicheClient: {
          type: Boolean,
          default: false,
          required: false
        },
        tiersData: {
          type: Object,
          default: null,
          required: false
        }
    },
    watch: {
      selectedAssureur() {
        this.filterData()
      },
      selectedIssue(){
        this.filterData()
        this.listassureurfilter()
      },
      selectedMotif(){
        this.filterData()
        this.listassureurfilter()
      },
      selectedStatus(){
        this.filterData()
        this.listassureurfilter()
      },
      'filtre.date_reclamation_entre_le': {
      handler(val) {
        this.filterData()
        this.listassureurfilter()
      }
    },
    'filtre.date_reclamation_el_le': {
      handler(val) {
        this.filterData()
        this.listassureurfilter()
      }
    },
    },
    mounted() {
 
    this.fetchAssureurList()
    this.fetchreclamation()
    this.fetchDataCommercialByCourtier()
    this.fetchProspectData()

      if (this.$route.params.id && this.$route.params.type && this.$route.params.interfaceReclamation){
        this.reclamationChosen.id = this.$route.params.id
        this.reclamationChosen.type = this.$route.params.type
        this.interfaceReclamation = 'FicheReclamation'
      }
    },
    methods: {
      joindreFile(reclamation, form = false){
      this.reclamationlist = null
      this.reclamationlist = reclamation
      if (!form)
        this.$root.$emit('bv::toggle::collapse', 'sidebar-importNewReclamtion')
      },
      stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html.replaceAll(/<\/[a-z]*>/ig, '\r').slice(0, -1);
        return tmp.textContent || tmp.innerText || "";
      },
      changeTextStatus(statut) {
        console.log(statut)
        if (statut)
          return this.StatussOptions.find((status) => status.value == statut).text;

      },
      hisotorie(id){
        this.$swal({
        title: 'Etes-vous sûr de vouloir historiser cette reclamation ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(result => {
              if (result.value) {
                this.showLoading = true
                this.$http
                  .post('/reclamations/updateReclamtionHisotorie', {
                    id: id,
                   })
                  .then(res => {
                    if (res.data.success) {
                      this.showLoading = false
                      this.fetchreclamation()
                      this.clearAllFilter()
                      this.messageToast('La reclamation est historie', 'Succès', 'success')
                    
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    console.error(err)
                  })
          
                }
              })
      },
      action_masse(action){
      switch (action) {
        case 'Historiser':
          if(this.selectedrows_masse.selectedRows.length > 0)
          {
            this.$swal({
              title: 'Etes-vous sûr de vouloir historiser cette reclamation ?',
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                this.showLoading = true;
                this.$http
                  .post("/reclamations/updateAllReclamtionHisotorie", {
                    'reclamation' : this.selectedrows_masse.selectedRows,
                  })
                  .then((res) => {
                    if (res.data.success) {
                      this.showLoading = false;
                      this.fetchreclamation();
                      this.clearAllFilter();
                      this.messageToast(
                        "Les reclamations sont historie",
                        "Succès",
                        "success"
                      );
                    }
                  })
                  .catch((err) => {
                    this.showLoading = false;
                    console.error(err);
                  });
              }
            });
           

               
          }
          break;
        default:
      }

    },
      showReclamation(data) {
        this.editReclamation(data);
        this.$router.push({
          name: 'reclamations-list',
          params: {
            'id': data.id,
            'type': data.etat,
            'interfaceReclamation': 'FicheReclamation',
            'reclamationForEdit' : this.reclamationForEdite
          }
        })
      },
      editReclamation(data,fromChild=false) {
      if(fromChild) {
          this.$http.get(`/reclamations/getReclamationDetailById/${data.id}`)
          .then(res => {
            this.isReclamationUpdated = false
            this.transformReclamationData(res.data.data,fromChild)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.transformReclamationData(data,fromChild)
      }
    },
    transformReclamationData(data,fromChild){
      this.reclamationForEdite.id = data.id
      if(fromChild){
        // 
      }else{
        this.reclamationForEdite.is_client        = data.reclamation_client ? true : false
        this.reclamationForEdite.num_reclamation  = data.num_reclamation
        this.reclamationForEdite.date_reclamation = data.date_reclamation ? data.date_reclamation : null
        this.reclamationForEdite.canal_reclamtion = data.canal_reclamtion,
        this.reclamationForEdite.date_acc_recep   = data.date_acc_recep ? data.date_acc_recep : null,
        this.reclamationForEdite.declarant        = data.declarant,
        this.reclamationForEdite.statut           = data.statut,
        this.reclamationForEdite.assureur_id      = data.assureur_id,
        this.reclamationForEdite.motif            = data.motif,
        this.reclamationForEdite.service          = data.service_concerne,
        this.reclamationForEdite.autre_service    = data.autre_service,
        this.reclamationForEdite.courtier_id      = data.courtier_id,
        this.reclamationForEdite.niveau           = data.niveau,
        this.reclamationForEdite.user             = {
          id:   data.pris_en_charge.personne_physique.user_id,
          nom:  data.pris_en_charge.personne_physique.nom + ' ' + data.pris_en_charge.personne_physique.prenom,
          type: data.pris_en_charge.personne_physique.type,
        },
        this.reclamationForEdite.comment          = data.comment,
        this.reclamationForEdite.issue            = data.issue,
        this.reclamationForEdite.date_cloture     = data.date_cloture ? data.date_cloture : null,
        this.reclamationForEdite.reponse_app      = data.reponse_app,
        this.reclamationForEdite.mesure_cor       = data.mesure_cor
        if(data.reclamation_client) {
          this.reclamationForEdite.reclamant  = {
            id: data.reclamation_client.id,
            idClient: data.reclamation_client.id,
            type: data.reclamation_client.type,
            categorie: data.reclamation_client.categorie,
            nameClient :data.reclamation_client.type === "PERSONNE_MORALE"
                ? data.reclamation_client.denomination_commercial
                : data.reclamation_client.nom +
                  " " +
                  data.reclamation_client.prenom,
            client: data.reclamation_client.type === 'PERSONNE_MORALE' ? data.reclamation_client.denomination_commercial : data.reclamation_client.nom + ' ' + data.reclamation_client.prenom,
            nom: null,
            prenom: null
          }
        } else {
          this.reclamationForEdite.reclamant.nom    = data.nom_reclamant
          this.reclamationForEdite.reclamant.prenom = data.prenom_reclamant
        }

        if(data.reclamation_reference) {
          if(data.referancable_type.includes('Devis')) {
            this.reclamationForEdite.reference = {
              id: data.reclamation_reference.id,
              numero: data.reclamation_reference.devis_no_externe ? data.reclamation_reference.devis_no_externe : data.reclamation_reference.devis_no_interne,
              source: data.reclamation_reference.devis_no_externe ? 'Externe' : 'Interne',
              type: 'PROJET',
              ref_externe: null
            }
          }
          if(data.referancable_type.includes('Contrat')) {
            this.reclamationForEdite.reference = {
              id: data.reclamation_reference.id,
              numero: data.reclamation_reference.police_no ? data.reclamation_reference.police_no : 'NC',
              source: data.reclamation_reference.source,
              type: 'CONTRAT',
              ref_externe: null
            }
          }
        } else {
          this.reclamationForEdite.reference.ref_externe = data.ref_externe
        }
        this.isUpdate = true
      }
      console.log(this.reclamationForEdite);
    },
    recordReclamationReturned(reclamation, message) {
        this.fetchreclamation()
        this.messageToast(message, 'Succès', 'success')
    },
    showProspect(id,type) {
      if (type == 'PERSONNE_PHYSIQUE') {
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': id,
            'type': type,
            'interfaceChosen': 'Professionnel'
          }
        })
      } else{
        this.$router.push({
          name: 'tiers-list',
          params: {
            'id': id,
            'type': 'PERSONNE_MORALE',
            'interfaceChosen': 'Entreprise'
          }
        })
      }
    },
      fetchDataCommercialByCourtier() {
        this.commercialOptions = []
        this.$http
          .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.commercialOptions.push({
                  id: el.user_id,
                  type: el.type,
                  nom: el.nom
                })
              })
            }
          })
          .catch(err => {
            this.commercialOptions = []
            console.error(err)
          })
    },
    fetchContratsList() {
      this.$http
          .post('/contrat/fetchDataContrats', {
            courtier_user_id: this.currentUser.courtier_user[0].id,
            user_id: this.currentUser.courtier_user[0].user_id,
            courtier_id: this.currentUser.courtier_user[0].courtier_id,
            clientId: this.prospectChosen.id,
            clientType:this.prospectChosen.type
          })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(element => {
                this.projetContratData.push({
                  id: element.id,
                  numero: element.police_no ? element.police_no : 'NC',
                  source: element.source,
                  type: 'CONTRAT',
                  ref_externe: null
                })
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
    },
    fetchDevisList() {
      this.$http
          .get(`/devis/fetchListDevisByTier/${this.prospectChosen.id}/${this.prospectChosen.type}`)
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(element => {
                this.projetContratData.push({
                  id: element.id,
                  numero: element.devis_no_externe ? element.devis_no_externe : element.devis_no_interne,
                  source: element.etude.source,
                  type: 'PROJET',
                  ref_externe: null
                })
              })
            }
          })
          .catch(err => {
            this.showLoading = false
            this.prospectDevisLocalKeeper = []
            this.prospectDevisLocal = []
            console.error(err)
          })
    },
    fetchDataRef(prospect) {
      this.projetContratData = []
      this.prospectChosen.id = prospect.id
      this.prospectChosen.type = prospect.type
      this.fetchContratsList()
      this.fetchDevisList()
    },
    fetchProspectData() {
      this.$http.get('/prospect/getAllProspect')
        .then(r => {
          this.pushAndFusiondata(r.data[0].concat(r.data[1]))
        })
        .catch(err=>{
          this.showLoading = false
          console.log(err)
        })
    },
    pushAndFusiondata(array) {
      this.prospect_filtred = []
      array.forEach(item => {
        
        this.prospect_filtred.push({
          id: item.id,
          client: item.type === 'PERSONNE_MORALE' ? item.denomination_commercial : item.nom + ' ' + item.prenom,
          nom: null,
          prenom: null,
          categorie: item.categorie,
          type: item.type
        })
      })
      this.prospectData = this.$_.orderBy(this.prospect_filtred, ['type'], ['desc'])
    },
    fetchAssureurList() {
       this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --'
      }]
      this.$http
          .post('/assureurs/fetchAssureurList')
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur,
                })
              })
            }
          })
          .catch(err => {
              this.assureursOptions = [{
                value: null,
                text: '-- Choisissez --'
              }]
            console.error(err)
          })
    },
    listassureurfilter(){
      this.assureur_list = [{
          value: null,
          text: '-- Choisissez --'
      }]
      this.rows.forEach(el => {
      this.assureur_list.push({
          value: el.assureur_id,
          text: el.assureur,
      })
    })
      this.assureur_options_list = Array.from(new Set(this.assureur_list.map(a => a.value)))
      .map(id => {
        return this.assureur_list.find(a => a.value === id)
      })
    },
    selectionChanged(params) {
        this.selectedrows_masse = params;
        
    },
    changeText(statut) {
      if(statut)
        return this.issueOptions.find((issue) => issue.value == statut).text
      return ''
    },
    searchInput(){
      this.showLoading = true
      setTimeout(() => {
        if(this.recherche != null) {
          this.searchTerm = this.recherche
          this.showLoading = false
        }
        this.showLoading = false
      }, 500);
    },
    clearAllFilter() {
      this.recherche = null
      this.selectedAssureur = null
      this.selectedIssue = null
      this.selectedMotif = null
      this.selectedStatus = 'EN_COURS'
      this.filtre.date_reclamation_entre_le = null
      this.filtre.date_reclamation_el_le = null
      this.searchTerm = ''
    },
    filterData() {
      this.rows = this.rowsConst
      if (this.selectedAssureur) {this.rows = this.rows.filter(item => item.assureur_id === this.selectedAssureur)}

      if (this.selectedIssue) this.rows = this.rows.filter(item => item.issue === this.selectedIssue)
      if (this.selectedMotif) this.rows = this.rows.filter(item => item.motif === this.selectedMotif)
      if (this.selectedStatus) this.rows = this.rows.filter(item => item.etat === this.selectedStatus)

      // let endDate = this.filtre.date_reclamation_el_le && new Date(this.filtre.date_reclamation_el_le);
      if (this.filtre.date_reclamation_entre_le){
        this.rows = this.rows.filter(item => new Date(moment(item.date_reclamation, 'DD/MM/YYYY').format('YYYY-MM-DD')) >=  new Date(moment(this.filtre.date_reclamation_entre_le, 'DD/MM/YYYY').format('YYYY-MM-DD')))
      }
      if (this.filtre.date_reclamation_el_le){
        this.rows = this.rows.filter(item => new Date(moment(item.date_reclamation, 'DD/MM/YYYY').format('YYYY-MM-DD')) <=  new Date(moment(this.filtre.date_reclamation_el_le, 'DD/MM/YYYY').format('YYYY-MM-DD')))
      }

      // if (endDate)
      //  this.rows = this.rows.filter(item => new Date(item.date_reclamation)  <=  startDate)

      this.json_data = this.rows
    },
    fetchreclamation() {
      this.showLoading = true
      this.rows = []
      this.$http.get(`/reclamations/getReclamationClient/${this.prospectChosene.type}/${this.prospectChosene.id}`)
          .then(res => {
            console.log(res)
            if (res.data.success) {
              this.rows = res.data.data
              this.rowsConst = res.data.data
              this.json_data = this.rows
              this.filterData()
              this.listassureurfilter()
              this.$emit('calculate',this.rowsConst.length)

              this.showLoading = false

            }
          })
          .catch(err => {
            this.showLoading = false
            this.rows = []
            this.json_data = this.rows
            console.error(err)
          })

        
    },
    changeTextmotif(text){
      let t = ''
      this.MotifOptions.forEach(element => {
        if(element.hasOwnProperty('label')) {
          element.options.forEach(motif => {
            if(motif.value == text) {
              t = motif.text
              return
            }
          })
        }
      })

      return t
    },
    isUpdateFunction(boolValue) {
        this.isUpdate = boolValue
    },
      backToListing(value) {
        this.interfaceReclamation = value
      },   
  
    }
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  <style>

  </style>
  