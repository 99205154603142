<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1" v-if="interfaceSinistre === 'Listing'">
        <h1 class="text-primary font-weight-bolder text-uppercase ml-1">Sinistres ({{ allDataFprFetch.total ? allDataFprFetch.total : 0 }})</h1>
      </div>
      <div class="mr-1 mb-1" v-if="interfaceSinistre === 'Listing'">
        <actions-sinistre 
        :interface-chosen="interfaceSinistre"
        :gestionnaire-options="gestionnaireOptions" 
        :assureur-options="assureursOptions" 
        :risque-options="risquesOptions"
        :current-user="currentUser" 
        :is-update.sync="isUpdate"
        :sinistre-for-edit="sinistreForEdit"
        @is-update-function="isUpdateFunction" 
        :prospect-options="prospectData"
        @fetch-data-ref="fetchDataRef"
        @record-sinistre-returned="recordSinistreReturned"
        :projet-contrat-data="projetContratData" />

      </div>
    </div>
  <div v-if="interfaceSinistre === 'Listing'">
    <b-card title="FILTRES">
      <div class="custom-search">
        <b-form ref="form">
          <!-- advance search input -->
          <b-row>
           
            <b-col md="3">
              <b-form-group
                label="Date de sinistre comprise entre le"
                label-for="entrele"
              >
                <flat-pickr
                  id="entrele"
                  v-model="filtre.date_sinistre_entre_le"
                  :config="configDate('entrele')"
                  class="form-control"
                  placeholder="Date de sinistre" @input="fetchSinistersList(1)"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Et le" label-for="et_le">
                <flat-pickr
                  id="et_le"
                  v-model="filtre.date_sinistre_el_le"
                  :config="configDate('et_le')"
                  class="form-control"
                  placeholder="Date de sinistre" @input="fetchSinistersList(1)"
                />
              </b-form-group>
            </b-col>
             <b-col md="3">
                    <b-form-group label="Type de sinistre" label-for="typeSinistre">
                      <b-form-select id="typeSinistre" v-model="selectedTypeSinistre" :options="typeSinistreOption" @input="fetchSinistersList(1)" />
                    </b-form-group>
                  </b-col>
          
            <b-col md="3">
              <b-form-group label="Sinistre en délégation" label-for="sinistreDelegation">
                <b-form-select id="sinistreDelegation" v-model="selectedDelegation" :options="delegationOptions" @input="fetchSinistersList(1)"/>
              </b-form-group>
            </b-col>
            <b-col md="2">
                <b-form-group label="Statut" label-for="statut">
                  <b-form-select 
                    id="statut"  
                    v-model="selectedStatus"
                    :options="statusOptions" @input="fetchSinistersList(1)" />
                
                </b-form-group>
              </b-col>
            <b-col md="2">
              <b-form-group label="Assureur" label-for="assureur">
                <b-form-select id="assureur" v-model="selectedAssureur" :options="assureursOptions" @input="fetchSinistersList(1)" />
              </b-form-group>
            </b-col>
            <b-col md="2">
                <b-form-group label="Risque" label-for="Risques">
                  <b-form-select id="Risques" v-model="selectedRisque" :options="risquesOptions" @input="fetchSinistersList(1)" />
                </b-form-group>
              </b-col>

                <b-col md="2">
                  <b-form-group label="Gestionnaire" label-for="Gestionnaire">
                    <b-form-select id="Gestionnaire" v-model="selectedGestionnaire" :options="gestionnaireOptionsFiltre" @input="fetchSinistersList(1)" />
                  </b-form-group>
                </b-col>
              
            <b-col md="3">
              <b-form-group label="Recherche" label-for="basic-recherche">
                <b-input-group>
                  <b-form-input
                    v-model="recherche"
                    :lazy="true"
                    id="basic-recherche"
                    class="d-inline-block"
                    type="text"
                    placeholder="Recherche"
                    @keyup.enter="fetchSinistersList(1)"
                  />
                  <b-input-group-append is-text>
                    <feather-icon @click="fetchSinistersList(1)" icon="SearchIcon" class="cursor-pointer" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col md="1" class="mt-1">
              <b-button v-b-tooltip.hover.top="'Tout afficher'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon mt-1" variant="primary" @click="clearAllFilter">
                  <feather-icon icon="RefreshCwIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
    <b-card >
      <b-overlay :show="showLoading" no-wrap />
      <!-- table -->
      <button
          class="btn btn-primary mb-1"
          :data="json_data"
          :fields="json_fields"
          v-if="can('Export Claims') || isPrincipal()"
          worksheet="sinistre"
          name="liste_sinistres.xls"
          @click="exportSinistre"
        >
          <span>Exporter </span>
          <feather-icon class="mr-50" icon="DownloadIcon" />
      </button>
      <vue-good-table
        class="custom-table-bottom"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
         @on-selected-rows-change="selectionChanged"

        :search-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'lignes sélectionnées',
          clearSelectionText: 'Effacer la sélection',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          mode: 'records',
          rowsPerPageLabel: '',
          dropdownAllowAll: false,
          perPageDropdownEnabled: false,
          ofLabel: 'de',
          infoFn: params => ``
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Contrat -->
          <span v-if="props.column.field === 'contrat_no' &&
                props.row.sinistre_reference != null" @click="showContrat(props.row.contrat_id, props.row.contrat_source)">     
            <a style="text-decoration: underline;">{{ props.row.contrat_no }}</a>             
          </span>
          <!-- Column : Sinistre -->
          <span
              v-else-if="props.column.field === 'numero_sinistre'"
              @click="showSinistre(props.row)"
            >
              <a style="text-decoration: underline"
                >{{ props.row.numero_sinistre }}
              </a>
            </span>
          <!-- Column: client -->
          <span
              v-else-if="
                props.column.field == 'client' &&
                props.row.sinistre_client != ''
              "
              @click="
                showProspect(
                  props.row.sinistre_client.id,
                  props.row.sinistre_client.type
                )
              "
            >
              <a style="text-decoration: underline">{{ props.row.client }}</a>
            </span>

          <!-- Column: statut --> 
          <span v-else-if="props.column.field === 'statut'">
                  {{ changeText(props.row.statut) }}
          </span>

           <span v-else-if="props.column.field === 'type_sinistre'">
                    {{ changeTextTypeSinistre(props.row.type_sinistre) }}
            </span>

          <!-- Column: client -->
           <span v-else-if="props.column.field == 'delegation'">
              {{ props.row.isDelegation == '1' ? 'Oui' : 'Non'  }}
           </span>  
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item v-if="can('Modify claim') || isPrincipal()" @click="editSinistre(props.row)" v-b-toggle.sidebar-sinistre>
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Modifier</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.statut != 'CLOS' || props.row.statut == 'EN_COURS'" @click="clotureSinistre(props.row)">
                  <feather-icon  icon="ArchiveIcon" class="mr-50" />
                  <span>Clôturer</span>
                </b-dropdown-item>
                <b-dropdown-item  v-if="props.row.statut != 'REOUVERT' && props.row.statut != 'EN_COURS'"  @click="rouvrir(props.row.id)">
                  <feather-icon  icon="RefreshCcwIcon" class="mr-50" />
                  <span>Rouvrir</span>
                </b-dropdown-item>
                <b-dropdown-item  @click="deleteSinistre(props.row)">
                  <feather-icon  icon="TrashIcon" class="mr-50" />
                  <span>Supprimer</span>
                </b-dropdown-item>
                 <b-dropdown-item 
                      @click="joindreFile(props.row)"
                    >
                      <feather-icon class="mr-50" icon="PaperclipIcon" />
                      <span>Joindre</span>
                    </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="(value) => {
                      fetchSinistersList(1);
                      props.perPageChanged({ currentPerPage: value });
                    }"
                  />
                  <span class="text-nowrap"> de {{ allDataFprFetch.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
          <b-row class="mt-2 align-items-center">
            <b-col>
              <b-input-group>
                <b-form-select v-model="selected_action" :options="options" />
                <b-input-group-append>
                  <b-button @click="action_masse(selected_action)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                    <span>Valider </span>
                    <feather-icon icon="ArrowRightIcon" class="mr-50" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col>

                  <!-- <pagination :data="rows" @pagination-change-page="fetchSinistersList"></pagination> -->

              <b-pagination :value="allDataFprFetch.current_page ? allDataFprFetch.current_page : 1 " :total-rows="allDataFprFetch.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" 
              @input="(value) => {
                // props.pageChanged({ currentPerPage: value });
                if (pagepagination != value) {
                  fetchSinistersList(value);
                  pagepagination = value;
                        
                }
                  
              }">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </template>
        <div class="text-center align-middle" slot="emptystate">
          <span>Aucune donn&eacute;e disponible dans le tableau</span>
        </div>
      </vue-good-table>
    </b-card>
   </div> 
   <div v-if="interfaceSinistre === 'FicheSinistre'">
    <parent-fiche-sinistre 
        @back-to-listing="backToListing" 
        @refresh-sinistre-list="fetchSinistersList" 
        :sinistre-chosen="sinistreChosen"
        :gestionnaire-options="gestionnaireOptions" 
        :assureur-options="assureursOptions"
        :risque-options="risquesOptions"
        :current-user="currentUser"
        :prospect-options="prospectData"
        :sinistre-for-edit="sinistreForEdit"
        @edit-sinistre="editSinistre"
        @fetch-sinisters-list="fetchSinistersList"
        @fetch-data-ref="fetchDataRef"
    />
  </div>
      <ActionsDocument v-if="interfaceSinistre === 'Listing'" @fetch-document="clearAllFilter" :sinistre = "sinistreList"  />

  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import ActionsDocument from "./ActionsDocument";
import { BPagination, BFormGroup, VBTooltip, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BForm, BCard, BDropdown, BDropdownItem, BOverlay, BInputGroup, BInputGroupAppend, VBToggle } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import sinistreOptions from "@/shared/constants/sinistre";

import Ripple from 'vue-ripple-directive'
import actionsSinistre from './actions-sinistre.vue'
import moment from "moment";
import ParentFicheSinistre from './detail_fiche_sinistre/ParentFicheSinistre.vue';
export default {
  components: {
    flatPickr,
    actionsSinistre,
    ActionsDocument,
    VueGoodTable,
    VBTooltip,
    BPagination,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormSelectOption,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    moment,
    ParentFicheSinistre,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      pagepagination:1,
      sinistreList:null,
      pageLength: 3,
      selected_action: null,
      page:1,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      dir: false,
      isUpdate: false,
      interfaceSinistre: "Listing",
      selectedTypeSinistre:null,
      options: [
        { value: null, text: 'Choisissez' },
        { value: 'Supprime', text: 'Supprimer' },
        { value: 'clos', text: 'Clôturer' },
        // { value: 'Archiver', text: 'Archiver' },
        // { value: 'Supprimer', text: 'Supprimer' }
      ],
      optionsTypeClient: [
        { value: null, text: '-- Choisissez --' },
        { value: 'PARTICULIER', text: 'Particulier' },
        { value: 'PROFESSIONNEL', text: 'Professionnel' },
        { value: 'ENTREPRISE', text: 'Entreprise' }
      ],
      // filter
      selectedGestionnaire: null,
      selectedAssureur: null,
      selectedRisque: null,
      selectedStatus:null,
      selectedDelegation:null,
      recherche : null,
      selectedCommercial: null,
      filtre: {
        date_sinistre_entre_le: null,
        date_sinistre_el_le: null,
      },
      typeSinistreOption: sinistreOptions['TYPES'],
      selected: null,
      prospectData: [],
      sinistreForEdit: {
              id: null,
              numero_sinistre: null,
              canal_sinistre : null,
              date_acc_recep : null,
              is_client:false,
              is_contrat:false,
              sinistree: {
                id: null,
                type: null,
                categorie: null,
                client: null,
                nom: null,
                prenom: null
              },
                reference: {
                id: null,
                numero: null,
                source: null,
                type: null,
                ref_externe: null,
                assureur_id:null,
                risque_id:null
              },
              risque_id : null,
              assureur_id : null,
              courtier_id: null,
              date_sinistre: null,
              type : null,
              statut: null,
              responsabilite : null,
              date_declaration: null,
              montant : null,
              isDelegation: 0,
              expert : null,
              date_cloture : null,
              gestionnaire : null,
            },
  
      clientsOptions: [],
      gestionnaireOptions: [],
      contratOptions : [],
      risquesOptions: [],
      assureursOptions: [],
      contratsOptions: [],
      statusOptions: sinistreOptions['STATUS'],
      delegationOptions : [
        {
          value: null,
          text: "-- Choisissez --",
        },
       {
          value: true,
          text: "oui",
        },
        {
          value: false,
          text: "non",
        },
      ],
      gestionnaireOptionsFiltre: [
        {
          value: null,
          text: "-- Choisissez --",
        },
      ],
      allDataFprFetch:{},

      columns: [
        {
          label: 'N° sinistre',
          field: 'numero_sinistre',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'N° contrat',
          field: 'contrat_no',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Client',
          field: 'client',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: "Date de sinistre",
          field: "date_sinistre",
          sortable: false,
          thClass:"text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: "Date de clôture",
          field: "date_cloture",
          sortable: false,
          thClass:"text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Statut',
          field: 'statut',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Type sinistre',
          field: 'type_sinistre',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Délégation',
          field: 'delegation',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Risque',
          field: 'risque',
          sortable: false,
          tdClass: 'align-middle',
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Assureur',
          field: 'assureur',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
         {
          label: 'Gestionnaire',
          field: 'gestionnaire_nom',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: "text-center align-middle bg-primary text-white unbreaking h6",
          tdClass: "text-center m-0 p-1 align-middle h6",
        }
      ],
      rows: [],
      json_data: [],
      rowsConst: [],
      projetContratData: [],
      prospectChosen: {
        id: null,
        type: null,
      },

      searchTerm: '',
      showLoading: false,
      sinistreChosen: {
        id: null,
        type: null,
      },
      selectedrows_masse : [],
      json_fields: {
        "N° sinistre": "numero_sinistre",
        "N° contrat" : "ref_externe",
        "Client": "client",
        "Date sinistre" :"date_sinistre" , 
        "Date de clôture" : "date_cloture",
        "Date de déclaration":"date_declaration",
        "Montant de sinistre" :"montant_sinistre",
        "Statut": "statut",
        "Type sinistre" : "type_sinistre" ,
        "Délégation" :"isDelegation",
        "Risque" : "risque",
        "Assureur" :"assureur",
        "Gestionnaire":"gestionnaire_nom",
        "Coordonnées de l’expert":"expert"

      },
      reference_type: null,
      
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {
    
  },
  mounted() {
    this.fetchRisqueList()
    this.fetchAssureurList()
    this.fetchSinistersList()
    this.fetchProspectData()
    this.fetchDataCommercialByCourtier()

    if (
      this.$route.params.id &&
      this.$route.params.type &&
      this.$route.params.interfaceSinistre &&
      this.$route.params.sinistreForEdit
    ) {
      this.sinistreChosen.id = this.$route.params.id;
      this.sinistreChosen.type = this.$route.params.type;
      this.interfaceSinistre = "FicheSinistre";
      this.sinistreForEdit = this.$route.params.sinistreForEdit
    }
  },
  methods: {
    joindreFile(sinistreList, form = false) {
      this.sinistreList = null
      this.sinistreList = sinistreList
      if(!form)
        this.$root.$emit('bv::toggle::collapse', 'sidebar-importNewSinistre')

    },
    selectionChanged(params) {
      this.selectedrows_masse = params;
    },
    action_masse(action) {
      switch (action) {
        case 'Supprime':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.deleteSinistre(this.selectedrows_masse.selectedRows)
          }
          break;
        case 'clos':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.clotureSinistre(this.selectedrows_masse.selectedRows)
          }
          break;
        default:
      }
    },
    deleteSinistre(row) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce sinistre ?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.showLoading = true;
          this.$http
            .post("/sinistre/deleteSinistre", {
              data: row,
            })
            .then((res) => {
              if (res.data.success) {
                this.showLoading = false;
                this.fetchSinistersList(this.page);
                this.messageToast(
                  "le sinistre est supprime",
                  "Succès",
                  "success"
                );
              }
            })
            .catch((err) => {
              this.showLoading = false;
              console.error(err);
            });
        }
      });
      
    },
    clotureSinistre(row) {

      console.log('11111111')
      this.$swal({
        title: 'Etes-vous sûr de vouloir clôturer ce sinistre ?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.showLoading = true;
          this.$http
            .post("/sinistre/cloture", {
              data: row,
            })
            .then((res) => {
              if (res.data.success) {
                this.showLoading = false;
                this.fetchSinistersList(this.page);
                this.messageToast(
                  "le sinistre est clôturer",
                  "Succès",
                  "success"
                );
              }
            })
            .catch((err) => {
              this.showLoading = false;
              console.error(err);
            });
        }
      });
      
    },
    rouvrir(id){
         this.$swal({
         title: 'Etes-vous sûr de vouloir rouvrir ce sinistre ?',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'Oui',
         cancelButtonText: 'Non',
         customClass: {
           confirmButton: 'btn btn-primary',
           cancelButton: 'btn btn-outline-danger ml-1'
         },
         buttonsStyling: false
         }).then(result => {
               if (result.value) {
                 this.showLoading = true
                 this.$http
                   .post('/sinistre/updateSinistreStatus', {
                     id: id,
                    })
                   .then(res => {
                     if (res.data.success) {
                       this.showLoading = false
                this.fetchSinistersList(this.page);
                      
                       this.messageToast('Le sinistre a été réouvert', 'Succès', 'success')
                     
                     }
                   })
                   .catch(err => {
                     this.showLoading = false
                     console.error(err)
                   })
           
                 }
               })
       },
    configDate(id) {
      return {
        dateFormat: "d/m/Y",
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode !== 8) {
            var ele = document.getElementById(id);
            var val = ele.value;

            if (
              (val.length === 2 || val.length === 5) &&
              ele.getAttribute("id")
            ) {
              val += "/";
            }
            ele.value = val;
            ele.dispatchEvent(new Event("input"));
          }
        },
      };
    },
    showSinistre(data){
      this.sinistreChosen.id = data.id;
      this.sinistreChosen.type = data.statut;
      this.interfaceSinistre = "FicheSinistre";
    },
    // Methode additional
    isUpdateFunction(boolValue) {
      this.isUpdate = boolValue
    },
    messageToast(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Succès',
          icon: 'BellIcon',
          text,
          variant: 'success'
        }
      })
    },

    // fetch Data
    fetchRisqueList() {
      this.risquesOptions = [{ value: null, text: '-- Choisissez --' }]
      this.$http
        .post('/risques/fetchRisqueList')
        .then(res => {
          
          if (res.data.success) {
            res.data.data.forEach(el => {
              this.risquesOptions.push({
                value: el.id,
                text: el.label
              })
            })
          }
        })
        .catch(err => {
          this.risquesOptions = [{ value: null, text: '-- Choisissez --' }]
          console.error(err)
        })
    },

    fetchDataRef(prospect) {
      this.projetContratData = [];
      this.prospectChosen.id = prospect.id;
      this.prospectChosen.type = prospect.type;
      this.fetchContratsListClient();
    },
    fetchContratsListClient() {

      this.$http
        .post("/contrat/fetchDataContrats", {
          courtier_user_id: this.currentUser.courtier_user[0].id,
          user_id: this.currentUser.courtier_user[0].user_id,
          courtier_id: this.currentUser.courtier_user[0].courtier_id,
          clientId: this.prospectChosen.id,
          clientType: this.prospectChosen.type,
        })
        .then((res) => {
          if (res.data.success) {
            res.data.data.forEach((element) => {
              this.projetContratData.push({
                id: element.id,
                numero: element.police_no ? element.police_no : "NC",
                source: element.source,
                type: "CONTRAT",
                ref_externe: null,
                assureur_id:element.assureur_id,
                risque_id:element.risque_id
              });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    backToListing(value) {
      this.interfaceSinistre = value;
    },
    recordSinistreReturned(){
      this.fetchSinistersList(1);
      this.messageToast(message, "Succès", "success");
    },
    fetchAssureurList() {
      this.assureursOptions = [{ value: null, text: '-- Choisissez --' }]
      this.$http
        .post('/assureurs/fetchAssureurList')
        .then(res => {
          if (res.data.success) {
            res.data.data.forEach(el => {
              this.assureursOptions.push({
                value: el.id,
                text: el.nom_assureur
              })
            })
          }
        })
        .catch(err => {
          this.assureursOptions = [{ value: null, text: '-- Choisissez --' }]
          console.error(err)
        })
    },
    fetchProspectData() {
      this.$http
        .get("/prospect/getAllProspect")
        .then((r) => {
          this.pushAndFusiondata(r.data[0].concat(r.data[1]));
        })
        .catch((err) => {
          this.showLoading = false;
          console.log(err);
        });
    },
    pushAndFusiondata(array) {
      this.prospect_filtred = [];
      array.forEach((item) => {
        this.prospect_filtred.push({
          id: item.id,
          client:
            item.type === "PERSONNE_MORALE"
              ? item.denomination_commercial
              : item.nom + " " + item.prenom,
          nom: null,
          prenom: null,
          categorie: item.categorie,
          type: item.type,
        });
      });
      this.prospectData = this.$_.orderBy(
        this.prospect_filtred,
        ["type"],
        ["desc"]
      );
    },

    showProspect(id, type) {
      if (type == "PERSONNE_PHYSIQUE") {
        this.$router.push({
          name: "tiers-list",
          params: {
            id: id,
            type: type,
            interfaceChosen: "Professionnel",
          },
        });
      } else {
        this.$router.push({
          name: "tiers-list",
          params: {
            id: id,
            type: "PERSONNE_MORALE",
            interfaceChosen: "Entreprise",
          },
        });
      }
    },
    showContrat(idContrat, typeContrat) {
      this.$router.push({
        name: 'contrats-list',
        params: {
          'id': idContrat,
          'type': typeContrat,
          'interfaceContrat': 'FicheContrat'
        }
      })
    },
    changeText(statut) {
      switch (statut) {
        case 'EN_COURS':
          return 'En cours'
          break
        case 'CLOS':
          return 'Clos'
          break
        case 'REOUVERT':
          return 'Réouvert'
          break
      }
    },
    changeTextTypeSinistre(type) {

      switch (type) {
        case 'DOMMAGE_CORPOREL':
          return 'Dommage corporel'
          break
        case 'DOMMAGE_MATERIEL':
          return 'Dommage matériel'
          break
        case 'DOMMAGE_CORPOREL_ET_MATERIEL':
          return 'Dommage corporel et matériel'
          break
      }
      
    },
    fetchDataCommercialByCourtier() {
      this.gestionnaireOptions = []
      this.$http
        .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
        .then(res => {
          if (res.data.success) {
            res.data.data.forEach(el => {
              this.gestionnaireOptions.push({
                id: el.user_id,
                type: el.type,
                nom: el.nom
              })
              this.gestionnaireOptionsFiltre.push({
                value: el.user_id,
                text: el.nom
              })
            })
          }
        })
        .catch(err => {
          this.gestionnaireOptions = []
          console.error(err)
        })
    },

    // actions data
    editSinistre(data) {

      this.sinistreForEdit.id = data.id
      this.sinistreForEdit.numero_sinistre = data.numero_sinistre
      this.sinistreForEdit.canal_sinistre = data.canal_sinistre
      
      this.sinistreForEdit.date_acc_recep = data.date_acc_recep ? data.date_acc_recep : null,
      this.sinistreForEdit.is_client = data.sinistre_client ? true : false;
      this.sinistreForEdit.is_contrat = data.sinistre_reference ? true : false;
      this.sinistreForEdit.courtier_id = data.courtier_id;
      if (data.sinistre_client) {
          this.sinistreForEdit.sinistree = {
            id: data.sinistre_client.id,
            type: data.sinistre_client.type,
            categorie: data.sinistre_client.categorie,
            nameClient : data.sinistre_client.type === "PERSONNE_MORALE"
                ? data.sinistre_client.denomination_commercial
                : data.sinistre_client.nom +
                  " " +
                  data.sinistre_client.prenom,
            client:
              data.sinistre_client.type === "PERSONNE_MORALE"
                ? data.sinistre_client.denomination_commercial
                : data.sinistre_client.nom +
                  " " +
                  data.sinistre_client.prenom,
            nom: null,
            prenom: null,
          };
        } else {
          this.sinistreForEdit.sinistree.nom = data.nom_sinistre;
          this.sinistreForEdit.sinistree.prenom = data.prenom_sinistre; 
          
        }     
  

      if (data.sinistre_reference) {
          if (data.referancable_type.includes("Contrat")) {
            this.sinistreForEdit.reference = {
              id: data.sinistre_reference.id,
              numero: data.sinistre_reference.police_no
                ? data.sinistre_reference.police_no
                : "NC",
              source: data.sinistre_reference.source,
              type: "CONTRAT",
              ref_externe: null,
              assureur_id:data.sinistre_reference.assureur_id,
              risque_id:data.sinistre_reference.risque_id
            };
          }
        
        }  else {
            this.sinistreForEdit.reference.ref_externe = data.ref_externe;
            this.sinistreForEdit.risque_id = data.risque_id ? data.risque_id : null
            this.sinistreForEdit.assureur_id = data.assureur_id  ? data.assureur_id  : null
        }
        

      this.sinistreForEdit.statut = data.statut ? data.statut : null
      this.sinistreForEdit.date_sinistre = data.date_sinistre
      this.sinistreForEdit.date_declaration = data.date_declaration 
      this.sinistreForEdit.responsabilite  = data.responsabilite
      this.sinistreForEdit.type  = data.type_sinistre
      this.sinistreForEdit.montant  = data.montant_sinistre
      this.sinistreForEdit.expert  = data.expert
      this.sinistreForEdit.isDelegation = data.isDelegation  
      
      this.sinistreForEdit.date_cloture = data.date_cloture

      if(data.gestionnaire){
          this.sinistreForEdit.gestionnaire = {
          id: data.gestionnaire.personne_physique.user_id,
          nom: `${data.gestionnaire.personne_physique.prenom} ${data.gestionnaire.personne_physique.nom}`,
          type: data.gestionnaire.personne_physique.type,
        }  
      }
          
      this.isUpdate = true
      
      

    },
    recordContratReturned(contrat, message) {
      if (!this.isUpdate) {
        this.rows.push(contrat[0])
        this.rowsConst.push(contrat[0])
        this.messageToast(message)
      } else {
        const indexContrat = this.rows.findIndex(el => el.id === contrat[0].id)
        this.rows.splice(indexContrat, 1)
        this.rows.push(contrat[0])

        const indexContratConst = this.rowsConst.findIndex(el => el.id === contrat[0].id)
        this.rowsConst.splice(indexContratConst, 1)
        this.rowsConst.push(contrat[0])
        // // eslint-disable-next-line prefer-destructuring
        // this.rows[indexContrat] = contrat[0]
        // // eslint-disable-next-line prefer-destructuring
        // this.rowsConst[indexContrat] = contrat[0]
        this.messageToast(message)
      }
    },
    filterData() {
      let objet = {}
      if (this.selectedAssureur) objet.assureur_id = this.selectedAssureur
      if (this.filtre.date_sinistre_entre_le) objet.date_sinistre_entre_le = this.filtre.date_sinistre_entre_le
      if (this.filtre.date_sinistre_el_le) objet.date_sinistre_el_le = this.filtre.date_sinistre_el_le
      if (this.selectedDelegation != null ) objet.isDelegation = this.selectedDelegation
      if (this.selectedStatus) objet.statut = this.selectedStatus
      if (this.selectedRisque) objet.risque_id = this.selectedRisque

      if (this.selectedTypeSinistre) objet.type_sinistre = this.selectedTypeSinistre
      if (this.selectedGestionnaire) objet.user_id = this.selectedGestionnaire

      if (this.recherche) objet.recherche = this.recherche

        console.log(objet)
            return objet
    },


    // fetch Data
    fetchSinistersList(page = 1) {
      this.page =  page
      this.showLoading = true
      this.$http
        .post('/sinistre/fetchDataSinistres?page=' + page, {
          per_page:this.pageLength,
          courtier_user_id: this.currentUser.courtier_user[0].id,
          user_id: this.currentUser.courtier_user[0].user_id,
          courtier_id: this.currentUser.courtier_user[0].courtier_id,
          filter: this.filterData()
        })
        .then(res => {
          if (res.data.success) {
            console.log(res.data.data.data)
            this.rows = res.data.data.data
            this.allDataFprFetch = res.data.data
            this.json_data = res.data.data.data;
            this.showLoading = false
          }
        })
        .catch(err => {
          this.showLoading = false
          this.json_data = [];
          console.error(err)
        })
    },
    exportSinistre() {
  const formData = new FormData()       
  formData.append('courtier_user_id',this.currentUser.courtier_user[0].id)
  formData.append('user_id', this.currentUser.courtier_user[0].user_id)
  formData.append('courtier_id', this.currentUser.courtier_user[0].courtier_id)
  formData.append('filter', this.filterData())

  const config = { headers: { 'Content-Type': 'multipart/form-data' }, responseType: "blob" }

  this.$http

    .post(`/sinistre/exportDataSinistres`, formData, config)
    .then((response) => {
      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute with the desired file name
      link.download = 'sinistres.xlsx';

      // Create a URL for the Blob and set it as the link's href
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Display a success message
      this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success');
    })
    .catch((error) => {
      console.error(error);

      // Display an error message
      this.messageToast('Une erreur a été survenue lors du téléchargement.', 'Erreur', 'warning');
    });
},

    downloadDocument(blob, name) {
      if (blob) {
        var fileURL = window.URL.createObjectURL(new Blob([blob]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${name}_${moment().format("YYYYMMDDHHmm")}.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    clearAllFilter() {
      this.selectedDelegation = null
      this.selectedAssureur = null
      this.selectedStatus = null
      this.filtre.date_sinistre_entre_le = null
      this.filtre.date_sinistre_el_le = null
      this.selectedRisque = null
      this.selectedTypeSinistre = null
      this.recherche = null
      this.selectedGestionnaire = null

      this.fetchSinistersList(1)

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
</style>
